import styled from "styled-components";
import Themes from "../../style/theme";
export const StyledProfilePage = styled.div`
   background-image:url('/assets/gear fade.png');
   background-size:80% auto;
   background-position:top right;
   background-repeat:no-repeat ;
   flex:1;
   width:100%;
   background-color:${Themes.white};
   display:flex;
   flex-direction:column;
   padding:20px;
   padding-left:20%;
   & > h1{
      background-color:${Themes.lightGrey};
      font-weight:100;
      color:${Themes.darkBack};
      padding:10px;
      border-radius:15px;
      max-width:600px;
      font-size:1.1rem;
      padding-left:30px;
   }
   .form-container{
      display:flex;
      flex-direction:column;
      max-width:300px;
      input[type="file"]{
         display:none;
      }
   }
   .img-contianer{
      width:100px;
      aspect-ratio:1/1;
      margin:20px auto;
      position:relative;
      overflow:hidden;
      cursor: pointer;
      & > img{
         background-color:${Themes.lightGrey};
         width:100px;
         height:100px;
         object-fit: cover;
         border-radius:50%;
         border:1px solid ${Themes.darkBack};
      }
      & > svg{
         position:absolute;
         bottom:0;
         right:0;
         background-color:${Themes.white};
         box-shadow:0px 0px 3px ${Themes.darkBack};
         border-radius:50%;
         aspect-ratio:1/1;
         padding:3px;
         color:${Themes.darkBack};
         margin:5px;
         
      }
   }
   @media only screen and (max-width: 600px) {
      background-image:none;
      padding:20px;
      .form-container{
         margin:auto;
      }
   }
`
