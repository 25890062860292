import { Close, DisabledByDefaultRounded } from '@mui/icons-material'
import React,{useState} from 'react'
import { StyledCustomTable } from './CustomTable.styled'
export default function CustomTable({theader = [],searchBy="",searchKey="",data = [],isLoading = false,actionButtons = []}) {
const [imgView, setImgView] = useState(false);
const [currentImg,setCurrentImg] =  useState("");
const openImg = (img)=>{
    setCurrentImg(img);
    setImgView(true);
 }
if(searchBy!=="") data = data.filter(d=>d[searchBy].includes(searchKey))
return (
 <StyledCustomTable> 
     {
         isLoading ? <img className='loading-svg' src="/assets/loading-blue.svg" alt="" />: (
            data.length > 0  ?
            <>
            {imgView ? <div onClick={()=>{setImgView(false)}} className='img-view-container'>
                <img className='loading-img' src="/assets/loading-blue.svg" alt="" />
                <img onLoad={(e)=>{e.target.style.display="block";e.target.previousElementSibling.remove()}} onClick={(e)=>e.stopPropagation()} src={currentImg} alt="" />
                <Close onClick={()=>setImgView(false)} />
            </div> : null}
            <table>
                <thead>
                    <tr>{theader.map(h=><th><span>{h}</span></th>)}{actionButtons.length > 0 ? <th>Actions</th> : null}</tr> 
                <tr>
                    <span>&nbsp;</span>
                </tr>
                </thead>
                <tbody>
                {data.map(d=> <tr className={`${d.disabled ? "row-disabled" : ""}`}>{Object.keys(d).map(e=>{
                       
                        if(e==="_id"||e==="disabled") return null;
                        if(e==="activeStatus") {
                            return <td><div className='cell-container'>{d.activeStatus? "Disabled" : "Enabled"}</div></td>
                        }
                        if(e==="imgUrl") return  <td><div className='cell-container'><img onClick={()=>openImg(d[e] ?? "/assets/profile.png")} onError={(e)=>e.target.src="/assets/profile.png"} src={d[e] ?? "/assets/profile.png"} alt="" /></div></td>
                        return <td><div className='cell-container'>{d[e].toString()}</div></td>
                })}<td><div className='cell-container'>{ actionButtons.filter((f,i)=>{
                    if(d.activeStatus!==undefined){
                           if(d.activeStatus===true && i == 1) return false;
                           else if(d.activeStatus===false && i == 0) return false;
                    }
                    return true;
                }).map(a=>{const clickHandler= d.disabled ? {onClick:()=>console.log("processing...")} : {onClick:()=>a.handler(d._id ?? null)}; return  <a.action {...clickHandler} />})}</div></td></tr>
                )}
                
                </tbody>       
            </table>
         </>  : <span className='no-records'>No records</span>
         )
     }
   
   
 </StyledCustomTable> 
)
}
