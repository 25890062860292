import React,{useState,useEffect} from 'react'
import { AuthButton } from '../../components'
import validator,{ nameFieldValidator, numberFieldValidator } from '../../services/validator';
import { StyledAdminAddProduct } from './AdminAddProduct.styled'
import { storage} from '../../firebase';
import {ref } from "@firebase/storage";
import { getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import {useDispatch, useSelector} from "react-redux";
import { Requests } from '../../services/requests';
import { useNavigate } from 'react-router-dom';
import { setCurrentAdminPage } from '../../redux/reducer/app.reducer';
export default function AdminAddProduct() {
const [productImg,setProductImg] = useState(null);
const [name,setName] = useState({value:"",isValid:false,error:""});
const [description,setDiscription] = useState({value:"",isValid:false,error:""});
const [price,setPrice] = useState({value:"",isValid:false,error:""});
const [gender,setGender] = useState("women");
const [size,setSize] = useState([]);
const [sizeCheckBox,setSizeCheckBox] = useState({xs:false,s:false,m:false,l:false,xl:false})
const [btnLoading,setBtnLoading] = useState(false);
const [imgSrc,setImgSrc] = useState("/assets/upload.jpg");
const user  = useSelector((state) => state.user.user);
const navigator = useNavigate();
const dispatch = useDispatch();
useEffect(()=>{
  dispatch(setCurrentAdminPage(1));
},[])
const handleSubmit = async (e)=>{
  e.preventDefault();
  if(name.isValid&&description.isValid&&price.isValid){
    setBtnLoading(true);
    const storageRef = ref(storage,`/products/${Date.now().toString()}}`);
    const uploadTask = uploadBytesResumable(storageRef,productImg);
    uploadTask.on("state_changed",(snapshot)=>{},(err)=>{
        console.log(err);
        setBtnLoading(false);
    },()=>{
       getDownloadURL(uploadTask.snapshot.ref).then(async (url)=>{
          try{
            await Requests.addProduct({
                 gender:gender,
                 productName:name.value,
                 size:size,
                 imgUrl:url,
                 description:description.value,
                 price:price.value
            },user.accessToken);
              navigator("/products");
          }catch(e){
             setBtnLoading(false);
             alert("failed to add prodcut");
         }
       })
    })
  }else{
    handleNameChange(name.value);
    handleDescriptionChange(description.value);
    handlePriceChange(price.value);
    setBtnLoading(false);
  }
}
const handleNameChange = (val)=>{
  const {message,result} = validator(val,[nameFieldValidator]);
  setName({value:val,isValid:result,error:message});
}
const handleDescriptionChange = (val)=>{
  const {message,result} = validator(val,[nameFieldValidator]);
  setDiscription({value:val,isValid:result,error:message});
}
const handlePriceChange = (val)=>{
  const {message,result} = validator(val,[numberFieldValidator]);
  setPrice({value:val,isValid:result,error:message});
}
const handleFileChange = (e)=>{
  setProductImg(e.target.files[0]);
  var reader = new FileReader();
  reader.onload = function(){
     setImgSrc(reader.result);
  }
  reader.readAsDataURL(e.target.files[0]);
}
useEffect(()=>{
   let temp = [];
   if(sizeCheckBox.xs) temp.push("XS");
   if((sizeCheckBox.s)) temp.push("S");
   if((sizeCheckBox.m)) temp.push("M");
   if((sizeCheckBox.l)) temp.push("L");
   if((sizeCheckBox.xl)) temp.push("XL");
   if(temp.length==0) temp = ["M"];
   setSize(temp);
},[sizeCheckBox]);
return (
 <StyledAdminAddProduct> 
     <form onSubmit={handleSubmit}>
            <div>
               <img onClick={(e)=>e.target.nextElementSibling.click()} src={imgSrc}  alt="" />
               <input  required onChange={handleFileChange} type="file" hidden />
               <div>
                   <input required onChange={(e)=>handleNameChange(e.target.value)}  disabled={btnLoading} placeholder='Design/Design name' />
                   <span className='input-error'>{name.error}</span>
                   <textarea required onChange={(e)=>handleDescriptionChange(e.target.value)} disabled={btnLoading} placeholder="Description"></textarea>
                   <span className='input-error'>{description.error}</span>
                   <input required  onChange={(e)=>handlePriceChange(e.target.value)} disabled={btnLoading} type="number" placeholder="Price" />
                   <span className='input-error'>{price.error}</span>
                   <span>Gender</span>
                   <div className='gender-container'>
                       <input onClick={()=>setGender("women")} checked={gender==="women"} disabled={btnLoading} name="gender" type="radio" /> <lable>Women</lable>
                   </div>
                   <div className='gender-container'>
                        <input onClick={()=>setGender("men")}  checked={gender==="men"}  disabled={btnLoading} name="gender" type="radio" /> <lable>Men</lable>
                   </div>
                   <span>Size</span>
                   <div className='size-container'>
                       <div><input  onChange={(e)=>setSizeCheckBox({...sizeCheckBox,xs:e.target.checked})} disabled={btnLoading} checked={sizeCheckBox.xs}  type="checkbox" /> <lable>XS</lable></div>
                       <div><input  onChange={(e)=>setSizeCheckBox({...sizeCheckBox,s:e.target.checked})} disabled={btnLoading} checked={sizeCheckBox.s} type="checkbox" /> <lable>S</lable></div>
                       <div><input  onChange={(e)=>setSizeCheckBox({...sizeCheckBox,m:e.target.checked})} disabled={btnLoading} checked={sizeCheckBox.m} type="checkbox" /> <lable>M</lable></div>
                       <div><input  onChange={(e)=>setSizeCheckBox({...sizeCheckBox,l:e.target.checked})} disabled={btnLoading} checked={sizeCheckBox.l} type="checkbox" /> <lable>L</lable></div>
                       <div><input  onChange={(e)=>setSizeCheckBox({...sizeCheckBox,xl:e.target.checked})} disabled={btnLoading} checked={sizeCheckBox.xl} type="checkbox" /> <lable>XL</lable></div>
                   </div>
               </div>
            </div>
            <div>
              <AuthButton disabled={btnLoading} bgAdmin={true} label="Add Item" type="submit" />
            </div>
     </form>
 </StyledAdminAddProduct> 
)
}
