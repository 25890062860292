import { AddCircle, AddShoppingCart, Edit, EventNote } from '@mui/icons-material'
import React,{useEffect, useState} from 'react'
import { useSelector,useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../firebase';
import { StyledAdminLayout } from './AdminLayout.styled'
export default function AdminLayout({children}) {
const [isNavOpened,setIsNavOpened] = useState(false);
const navigator = useNavigate();
const user  = useSelector((state) => state.user.user);
const currentAdminPage = useSelector((state) => state.app.currentAdminPage);
useEffect(()=>{
   for(let i = 0; i <   document.getElementsByClassName('nav-list').length; i++)
   document.getElementsByClassName('nav-list')[i].classList.remove("active-nav");
   document.getElementsByClassName('nav-list')[currentAdminPage].classList.add("active-nav");
   setIsNavOpened(false);
},[currentAdminPage]);
const handleNavClick = (acitveNav)=>{
    switch(acitveNav){
        case 0:
            navigator("/")
            break;
        case 1:
            navigator("/addproduct")
            break;
        case 2:
            navigator("/products")
            break;
       case 3:
           navigator("/users")
           break;
        default:
            navigator("/")
    }
}
return (
 <StyledAdminLayout> 
     <header>
     <img onClick={()=>setIsNavOpened(!isNavOpened)}  className={`${isNavOpened ? "nav-opened" : ""}`} src="/assets/menu.svg" alt="" />
        <div>
            <div>
                <img src="/assets/profile.png" alt="" />
                <span>{user ? user.displayName : "Admin"  }</span>
            </div>
            <span onClick={()=>{logout();window.location.href = "/"}}>Logout</span>
        </div>
     </header>
     <div>
         <nav className={`${isNavOpened ? "nav-opened" : ""}`}>
             <ul>
                 <li onClick={()=>handleNavClick(0)} className='nav-list'><span></span><AddCircle /><span>Orders</span></li>
                 <li onClick={()=>handleNavClick(1)} className='nav-list'><span></span><AddShoppingCart /><span>Add Product</span></li>
                 <li onClick={()=>handleNavClick(2)} className='nav-list'><span></span><Edit /><span>Products</span></li>
                 <li onClick={()=>handleNavClick(3)} className='nav-list'><span></span><EventNote /><span>Users</span></li>
             </ul>
        </nav>
        <main>
          {children}
        </main>
     </div>
    

   
 </StyledAdminLayout> 
)
}
