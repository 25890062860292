import styled from "styled-components";
import Themes from "../../style/theme";
export const StyledAdminLayout = styled.div`
  width:100%;
  min-height:100vh;
  background-color:${Themes.bgGrey};
  display:flex;
  flex-direction:column;
  position:relative;
  & > header{
    width:100%;
    display:flex;
    justify-content:center;
    background-color:${Themes.white};
    height:45px;
    padding-right:10px;
    position:sticky;
    top:0;
    z-index:9;
    img{
        height:35px;
    }
    & > img{
        margin:0;
        height:100%;
        padding:5px;
        margin-right:auto;
        cursor: pointer;
        transition:background-color 0.5s;
        &.nav-opened{
            background-color:${Themes.bgGrey};
        }
    }
      & > div{
          align-items:center ;
          flex:1;
          display:flex;
          justify-content:space-between;
          max-width:1000px;
          margin-right:auto;
          & > div{
              display:flex;
              align-items:center;
              margin:0px 10px;
              cursor:default;
              & > *{
                  margin:0px 5px;
              }
              img{
                  border-radius:50%;
              }
             
          }
          & > span{
              transition:opacity 0.3s;
              &:hover{
                  cursor:pointer;
                  opacity:0.3;
              }
          }
      }
  }
  & > div{
      display:flex;
      flex:1;
      background-color:${Themes.bgGrey} ;
      & > nav{
          position:fixed;
          top:45px;
          bottom:0%;
          z-index:8;
          max-width:200px;
          display:flex; 
          overflow:hidden;
          padding-top:20px;
          background-color:${Themes.white} ;
          transition:background-color 0.5s;
          ul{
              flex:1;
          }
          &.nav-opened{
            background-color:${Themes.bgGrey} ;
              li{
                &:hover{
                   cursor:pointer;
                   background-color:${Themes.white};
                }
                & > span:last-child{
                   width:200px;
                   height:auto;
                   margin-left:10px;
                   padding-right:10px;
                }
              }
          }
          li{
            display:flex;
            align-items:center;
            margin:5px 0px;
            flex:1;
            transition:all 0.5s;
            &.active-nav{
                & > span:first-child{
                    background-color:${Themes.niceBlue};
               }
               svg{
                   color:${Themes.niceBlue};
               }
            }
            &:hover{
                cursor:pointer;
                background-color:${Themes.bgGrey};
            }
            & > span:first-child{
                flex:none;
                width:4px;
                background-color:transparent;
                align-self:stretch;
                border-radius:50px;
                margin:5px;
            }
            svg{
                margin-right:20px;
                color:${Themes.darkerBlack};
                margin:5px;
                margin-left:0;
            }
             & > span:last-child{
                margin-left:0px;
                padding-right:0px;
                overflow:hidden ;
                width:0;
                height:0;
                transition:all 0.5s;
                white-space: nowrap;
                word-break:break-word;
             }
          }
      }
      & > main{
          flex:1;
          padding:15px;
          margin:20px;
          margin-left:70px;
          background-color:${Themes.white};
          border-radius:25px;
          border:none;
          overflow:auto;
          display:flex;
          flex-direction:column ;
      }
  }
  @media only screen and (max-width: 500px) {
      & > div{
        & > main{
          margin:5px;
          margin-left:50px;
          border-radius:10px;
            }
        }
      }
`
