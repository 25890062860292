import { initializeApp } from "firebase/app";
const app = initializeApp({
    apiKey: "AIzaSyAgpCCQykobOg0ho8jX_OJAHGTnaVMZWwI",
    authDomain: "emilka-7362d.firebaseapp.com",
    projectId: "emilka-7362d",
    storageBucket: "emilka-7362d.appspot.com",
    messagingSenderId: "808525282336",
    appId: "1:808525282336:web:447acbb467dad8e8a41045"
});

export default app;