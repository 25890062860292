import styled from "styled-components";
import Themes from "../../style/theme";
export const StyledCoverBG = styled.div`
    position:fixed;
    width:100vw;
    height:100vh;
    top:0;
    box-sizing:border-box;
`;
export const StyledCustomeDropdown = styled.div`
  border-radius:10px;
  border:1px solid ${Themes.primary};
  display:flex;
  flex-direction:column;
  position:relative;
  background-color:${Themes.white};
  margin:5px;
  &.dark{
    border:1px solid ${Themes.darkBack};
    & > span{
      & >svg{
        color:${Themes.darkBack};
      }
    }
  }
  & > span{
    display:flex;
    align-items:center;
    justify-content:space-between;
    padding:3px;
    font-size:0.9rem;
    color:${Themes.darkBack};
    cursor: pointer;
    & > svg{
      color:${Themes.primary};
      transform:scale(1.5);
    }
  }
  & > div{
    position:absolute;
    top:35px;
    background-color:${Themes.white};
    min-width:100%;
    max-height:200px;
    font-size:0.9rem;
    display:flex;
    flex-direction:column;
    transform:opacity ;
    height:0;
    overflow-x:hidden;
    overflow-y:auto;
    border-radius:5px;
    z-index:1;
    box-shadow:1px 1px 3px rgb(0 0 0 / 0.3);
    &.active-custom-dropdown{
      height:auto;
      padding:5px;
    }
    & > span{
      color:${Themes.darkBack};
      white-space: nowrap;
      word-break:keep-all;
      padding:5px;
      &:hover{
        opacity:0.8;
        cursor:pointer;
      }
    }
  }
`
