import styled from "styled-components";
import Themes from "../../style/theme";
export const StyledUpdateInput = styled.div`
        display:flex !important;
         flex-direction:column;
         & > label{
            color:${Themes.darkBack};
            font-weight:600;
            margin:5px 0px;
         }
         & > input{
            padding:6px;
            border-radius:15px;
            border:none;
            outline:0;
            background-color:${Themes.lightGrey} ;
         }
         & > span{
            color:${Themes.warningLight};
            font-size:0.9rem;
            margin:5px 0px;
         }
`
