import styled from "styled-components";
import Themes from "../../style/theme";
export const StyledCartPage = styled.div`
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   .checkout-container{
       top:75px;
       position:sticky;
       display: flex;
       flex-direction: column;
       background-color: ${Themes.lightGrey};
       min-height:100px;
       align-self: flex-start;
       margin:20px 0px;
       padding:10px;
       border-radius:15px;
       font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
       box-shadow:1px 1px 3px rgb(0 0 0 / 0.2);
       & > div{
           display: flex;
           justify-content: space-between;
           margin:5px 0px;
           h1{
               color:${Themes.darkBack};
               font-weight:500;
               padding:10px 0px;
               border-bottom:1px solid rgb(0 0 0 / 0.2);
           }
           b{
            color:${Themes.darkBack};
            font-weight:500;
           }
       }
       .phone-container{
           padding:5px;
           background-color:${Themes.white};
           align-items:center ;
           border-radius:5px ;
           & > input{
               flex:1;
               border:none;
               padding:5px;
               outline:0;
           }     
       }
       & > span{
               color:${Themes.warningLight}
           }  
       & > button{
           margin:auto;
           margin-top:20px;
       }
   }
   .cart-container{
       display: flex;
       flex-wrap:wrap;
       justify-content: space-between;
       &>div{
           margin:20px;
       }
       & > div:first-child{
          max-width: 400px;
          overflow: hidden;
          display:flex;
          justify-content:center;
          align-items:center;
          flex-wrap:wrap;
          & > img{
              width: 100%;
              border-radius: 15px;
          }
      }
      & > div:last-child{
          align-self: flex-end;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
           color:${Themes.darkBack};
          flex:1;
          b{
              font-size:1.5rem;
              font-weight:400;
          }
          h4{
            font-weight:500;
              font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
          }
          & > h3{
              font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
              font-weight:400;
              font-size:1.2rem;
          }
          & > p{
              margin: 20px 0px;
              font-family:input;
              font-size:1rem;
          }
          & > button{
              margin:10px 0px;
              width:150px;
          }
          & > div,& > span{
              display: flex;
              margin:5px 0px;
              font-weight:500;
              font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
          }
        }
   }
`
