import styled from "styled-components";
import Themes from "../../style/theme";
export const StyledAuthInput = styled.div`
   display: flex;
   flex-direction: column;
   margin-bottom: 10px;
   input{
       padding: 7px 15px;
       outline: 0;
       border:none;
       border-radius: 5px;
       width:100%;
       font-family:input;
       color:${Themes.darkBack};
       font-weight:100;
   }
   span{
       margin-top:10px;
       font-size: 0.8rem;
       color:${Themes.warningLight};
   }
`
