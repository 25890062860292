import styled from "styled-components";
import Themes from "../../style/theme";
export const StyledProductsPage = styled.div`
  display: flex;
  flex-direction: column;
  .dropdown-container{
    display:flex;
    flex-direction:row !important;
    align-items:center;
    flex-wrap:wrap;
    white-space:word-spacing;
    word-break:keep-all;
    max-width:1200px;
    width:100%;
    margin:auto;
  }
  .gender-info{
       display: flex;
       justify-content: space-evenly;
       margin:10px 0px;
       & > h1{
           color:${Themes.darkBack};
       }
       & > .search-cont{
           flex:1;
           max-width:400px;
           height:auto;
           background-color: white;
           display: flex;
           align-items: center;
           border:1px solid ${Themes.primary};
           border-radius:50px;
           overflow: hidden;
           & > input{
               flex:1;
               outline:0;
               border:none;
               padding:10px;
               color:${Themes.darkBack};
               font-size:1.05rem;
           }
           & > svg{
               margin:10px;
               opacity: 0.5;
               cursor: pointer;
           }
       }
  }
  @media only screen and (max-width:600px){
    & > .gender-info{
       flex-direction: column;
       align-items: center;
       & > .search-cont{
           width:100%;
           & > input{
             padding:5px;
             min-width:0px;
             flex:1;
             font-size:1rem;
           }
           & > svg{
               margin:6px;
           }
       }
    }
  }
`
