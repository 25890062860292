import styled from "styled-components";
import Themes from "../../style/theme";
export const StyledCustomTable = styled.div`
  flex:1;
  position:relative;
  display:flex;
  flex-direction:column;
  overflow:auto;
  .no-records{
      margin:auto;
      font-size:rem;
      opacity:0.5;
  }
  .loading-svg{
      width:50px;
      margin:auto;
  }
  .img-view-container{
      position:fixed;
      background-color:rgb(0 0 0 / 0.5);
      top:0;
      left:0;
      z-index:15;
      width:100%;
      height:100%;
      overflow:auto;
      display:flex;
      img{
          margin:auto;
          height:100%;
          background-color:blue;
          display:none;
          &.loading-img{
              background-color:transparent;
              width:50px;
              display:block;
          }
      }
      svg{
          background-color:rgb(0 0 0 / 0.5);
          margin:5px;
          border-radius:5px;
          color:white;
          position:fixed;
          top:0;
          right:0;
          cursor:pointer;
      }
  }
  table{
     width:auto;
     border-collapse:collapse;
     font-family:input;
     thead{
         tr:first-child{
            border-bottom:1px solid transparent;
         }
         tr:last-child{
            span{
                display:block;
                height:10px;
            }
         }
         th{
           color:${Themes.darkerBlack};
           border-left:1px solid ${Themes.bgGrey};
           &:first-child{
               border-left:1px solid transparent;
           }
           span{
            width:100%;
           }
         }
     }
     th,td{
         padding:5px;
         white-space:nowrap;
         word-break:break-word;
     }
     tr{
        border-bottom:1px solid ${Themes.bgGrey};
        &.row-disabled{
            opacity:0.3;
        }
     }
     .cell-container{
         display:flex;
         align-items:center;
         img{
             background-color:${Themes.niceGrey};
             min-width:100px;
             width:100%;
             height:35px;
             object-fit: cover;
         }
         svg{
             padding:3px;
             color:${Themes.darkerBlack};
             cursor:pointer;
         }
     }
  }
  @media only screen and (max-width: 500px) {
    .img-view-container{
      img{
       width:100%;
       height:auto;
      }
  }
`
