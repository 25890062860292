import styled from "styled-components";
import Themes, { Theme } from "../../style/theme";
export const StyledAuthButton = styled.button`
   width: 100px;
   margin:auto;
   padding:7px;
   border-radius: 15px;
   border:none;
   outline:0;
   background-color: ${Themes.primary};
   color:${Themes.white};
   transition: opacity 0.3s;
   margin-bottom: 30px;
   &.admin-btn{
       background-color:${Themes.niceBlue};
   }
   img{
       width:90%;
   }
   &:hover{
       opacity: 0.7;
       cursor: pointer;
   }
`
