import React,{useEffect,useRef} from 'react'
import { StyledUpdateInput } from './UpdateInput.styled'
export default function UpdateInput({label,error,disabled = false,type,val="",handelOnChange}) {
const doc  = useRef(null);
useEffect(()=>{
  if(doc&&doc.current) doc.current.value = val;
},[]);
return (
 <StyledUpdateInput> 
          <label>{label}</label>
          <input disabled={disabled} autoComplete="none" ref={doc} type={type}  required onChange={handelOnChange} />
          <span>{error}</span>
 </StyledUpdateInput> 
)
}
