import React from 'react'
import { useSelector } from 'react-redux'
import { StyledPageNotFound } from './PageNotFound.styled'
export default function PageNotFound() {
const appStarted = useSelector(state=>state.app.appStarted);
return (
 <StyledPageNotFound> 
     {
         appStarted ? "404 page not found":
         <img src="/assets/loading.svg" alt="" /> 
     }
 </StyledPageNotFound> 
)
}
