import React from 'react'
import { useSelector} from 'react-redux';
import {Navigate} from "react-router-dom";
import { verifyEmail } from '../firebase';
export default function PrivateRoute({children}) {
   const user  = useSelector((state) => state.user.user);
   return (
       <>
       {
           user ?  (user.emailVerified ? children :((()=>{verifyEmail();return <Navigate replace to="/message_verify_again" />})()) ) : 
           <Navigate replace to="/" />
       }
       
       </>
      
   )
}
