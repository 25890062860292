import { Close, Search } from '@mui/icons-material';
import React,{useEffect,useState} from 'react'
import { AuthButton, CustomeDropdown, ProductsView } from '../../components'
import { Requests } from '../../services/requests';
import { StyledProductsPage } from './ProductsPage.styled'
export default function ProductsPage({type}) {
const [products,setProducts] = useState([]);   
const [loadingProducts,setLoadingProducts] = useState(true);
const [searchKey,setSearchKey] = useState("");  
const [selectedSort,setSelectedSort] = useState(0);
const [selectedFilter,setSelectedFilter] = useState(0);
const [showSeeMore,setShowSeeMore] = useState(false);
const [currentPage,setCurrentPage] = useState(1);
const itemsSort = ["Newest","Price (Asc)","Price (Dsc)"];
const itemsFilter = ["ALL","XS","S","M","L","XL"];
const fetchProducts = ()=>{
    setLoadingProducts(true);
    if(currentPage!=-1){
      setShowSeeMore(false);
      Requests.getAllProducts({page:currentPage,gender:type,key:searchKey,sort:itemsSort[selectedSort],size:itemsFilter[selectedFilter || ""]}).then(res=>{
        if(res.data.length == 0){
          setCurrentPage(-1);
          setShowSeeMore(false);
        }else{
              setProducts([...products,...res.data]);
              Requests.getAllProducts({page:currentPage+1,gender:type,key:searchKey,sort:itemsSort[selectedSort],size:itemsFilter[selectedFilter || ""]}).then(res2=>{
                    if(res2.data.length == 0){
                            setCurrentPage(-1);
                            setShowSeeMore(false);
                    }else{
                      setShowSeeMore(true);
                      setCurrentPage(currentPage+1);
                    }
              }).catch((e)=>{
                    console.log(e); 
              })
             
        }
        setLoadingProducts(false);
       }).catch(e=>{
             setLoadingProducts(false);
       });
    }
     else   setLoadingProducts(false);
}
useEffect(async ()=>{
  if(currentPage==1) fetchProducts();
},[currentPage]);
useEffect(()=>{
  setCurrentPage(1);
  setProducts([]);
},[selectedFilter,selectedSort])
const handleSearch = ()=>{
  setCurrentPage(1);
  setProducts([]);
}
const handelSortDropdown = (i)=>{
  setSelectedSort(i);
}
const handelFilterDropdown = (i)=>{
     setSelectedFilter(i);
  }
return (
 <StyledProductsPage> 
     <div className='gender-info'>
         <h1>{ type==="men"?  "Men" : "Women"}</h1>
         <div className="search-cont">
            <input onKeyUp={(e)=>{if(e.key === 'Enter') handleSearch()}} onChange={(e)=>setSearchKey(e.target.value)} placeholder='Search....' />
            <Search onClick={handleSearch} />
         </div>
     </div>
     <div className='dropdown-container'>
         <span>Sort by: </span>
          <CustomeDropdown key={1} items={itemsSort} selected={selectedSort} handleSelected={handelSortDropdown} />
         <span>Filter by Size: </span>
          <CustomeDropdown key={2} items={itemsFilter} selected={selectedFilter} handleSelected={handelFilterDropdown} />
    </div>
     <ProductsView products={products} isLoading={loadingProducts} /> 
     {showSeeMore ? <AuthButton onClick={fetchProducts} label="See more" /> : null}
 </StyledProductsPage> 
)
}
