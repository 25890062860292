import React,{useEffect, useState} from 'react'
import { StyledProductPage } from './ProductPage.styled';
import {useParams,useNavigate} from "react-router-dom";
import { Requests } from '../../services/requests';
import { AuthButton, CustomeDropdown } from '../../components';
import {useSelector,useDispatch} from "react-redux";
import {logout,verifyEmail} from "../../firebase";
import { updateCartCount } from '../../redux/reducer/user.reducer';
import { setCurrentAuthPage } from '../../redux/reducer/app.reducer';
import { Add, Remove } from '@mui/icons-material';
export default function ProductPage() {
const [product,setProducts] = useState(null);
const [quantity,setQuantity] = useState(1);
const [isProductLoading,setIsProductLoading] = useState(true);
const [btnLoading,setButtonLoading] = useState(false);
const [selectedSize,setSelectedSize] = useState(0);
const user  = useSelector((state) => state.user.user);
const dispatch = useDispatch();
const navigator = useNavigate();
const {id} = useParams();
useEffect(()=>{
   setIsProductLoading(true);
   Requests.getProduct(id).then((res)=>{
       if(typeof res.data==="object"){
         setProducts(res.data);
       }else setProducts(null);
       setIsProductLoading(false);
   }).catch((e)=>{
    setIsProductLoading(false);
   });
},[]);
useEffect(()=>{
  if(Number(quantity) <= 0 ) setQuantity(1);
},[quantity]);
const handleAddToCart = ()=>{
   if(btnLoading) return;
   if(user&&user.accessToken){
     if(!user.emailVerified) {
         verifyEmail();
         navigator("/message_verify_again");
         return;
    }
     setButtonLoading(true);
     Requests.addToCart({name:product.productName,imgUrl:product.imgUrl,description:product.description,price:product.price,quantity:quantity,size:product.size[selectedSize]},user.accessToken).then(async res=>{
        if(user&&user.accessToken){
            try{
              const count = await Requests.getCarts(user.accessToken);
              dispatch(updateCartCount(count.data.length));
            }catch(e){
               dispatch(updateCartCount(0));
            }
       }
        if(product.gender === "men") navigator("/products/men");
        else navigator("/products/women"); 
     }).catch(e=>{
         window.location.href =  window.location.href;
         console.log(e);
         setButtonLoading(false)
     })
   }else{
       logout();
       dispatch(setCurrentAuthPage(1));
   }
}
const handelSizeDropdown = (i)=>{
  setSelectedSize(i);
}
function magnify(imgID, zoom) {
  var img, glass, w, h, bw;
  img = document.getElementById(imgID);
  glass = document.createElement("DIV");
  glass.setAttribute("class", "img-magnifier-glass");
  img.parentElement.insertBefore(glass, img.nextElementSibling);
  glass.style.backgroundImage = "url('" + img.src + "')";
  glass.style.backgroundRepeat = "no-repeat";
  glass.style.backgroundSize = (img.width * zoom) + "px " + (img.height * zoom) + "px";
  bw = 3;
  w = glass.offsetWidth / 2;
  h = glass.offsetHeight / 2;
  glass.addEventListener("mousemove", moveMagnifier);
  img.addEventListener("mousemove", moveMagnifier);
  glass.addEventListener("touchmove", moveMagnifier);
  img.addEventListener("touchmove", moveMagnifier);
  function moveMagnifier(e) {
    var pos, x, y;
    e.preventDefault();
    pos = getCursorPos(e);
    x = pos.x;
    y = pos.y;
    if (x > img.width - (w / zoom)) {x = img.width - (w / zoom);}
    if (x < w / zoom) {x = w / zoom;}
    if (y > img.height - (h / zoom)) {y = img.height - (h / zoom);}
    if (y < h / zoom) {y = h / zoom;}
    glass.style.left = (x - w) + "px";
    glass.style.top = (y - h) + "px";
    glass.style.backgroundPosition = "-" + ((x * zoom) - w + bw) + "px -" + ((y * zoom) - h + bw) + "px";
  }
  function getCursorPos(e) {
    var a, x = 0, y = 0;
    e = e || window.event;
    a = img.getBoundingClientRect();
    x = e.pageX - a.left;
    y = e.pageY - a.top;
    x = x - window.pageXOffset;
    y = y - window.pageYOffset;
    return {x : x, y : y};
  }
}
return (
 <StyledProductPage> 
    {
        isProductLoading ?   <object data="/assets/loading.svg"> </object> :
        (
            product===null ? <span>Product not found</span> :
            <div>
                <div>
                       <object data="/assets/loading.svg"> </object>
                       <img id="product-img" onLoad={(e)=>{e.target.hidden=false;e.target.previousElementSibling.remove();magnify("product-img", 3);}} src={`${product.imgUrl}`} alt="" />
                </div>
                <div>
                    <h3>{product.productName}</h3>
                    <p>{product.description}</p>
                    <div className="size-container">
                    <h4>Size - </h4>
                    <CustomeDropdown dark={true} items={product.size} selected={selectedSize} handleSelected={handelSizeDropdown} />
                    </div>
                    <div className="quantity-container">
                        <h4>Quantity - </h4>
                        <div>
                            <span>{quantity}</span>
                            <Add onClick={()=>setQuantity(quantity+1)}/>
                            <Remove onClick={()=>setQuantity(quantity-1)} />
                        </div>   
                    </div>
                    <span><b>{(Number(product.price)*Number(quantity)).toLocaleString()} ETB</b></span>
                    <AuthButton type="button" disabled={btnLoading} onClick={handleAddToCart}  label="Add to Cart" />
                </div>
            </div>
        )
    }
 </StyledProductPage> 
)
}
