import styled from "styled-components";
import Themes from "../../style/theme";
export const StyledResetAccount = styled.div`
  display: flex;
    flex-direction: column;
    align-items: center;
    h1{
       font-weight: 100;
       margin-bottom: 30px;
   }
    form{
        width:100%;
        display: flex;
        flex-direction: column;
    }
`
