import React from 'react'
import { StyledAuthButton } from './AuthButton.styled'
export default function AuthButton0({disabled = false,type="submit",bgAdmin=false,onClick=()=>{},label}) {
return (
 <StyledAuthButton className={`${bgAdmin ? "admin-btn" : ""}`} onClick={onClick} type={type} disabled={disabled}> 
        {
            disabled ?
            <img src="/assets/loading btn.svg" alt="" />
            : label
        }
 </StyledAuthButton> 
)
}
