import styled from "styled-components";
import Themes from "../../style/theme";
export const StyledAdminEditProduct = styled.div`
 display:flex;
  flex-direction:column;
  align-items:center;
  width:100%;
  & > form{
    width:100%;
    max-width:600px;
    padding:5px;
    display:flex;
    flex-direction:column;
    font-family:input;
    & > div:first-child{
        display:flex;
        flex-wrap:wrap;
        justify-content:center;
       & > div{
           flex:1;
           padding:5px;
           display:flex;
           flex-direction:column;
           & > div ,& > span{
            margin:10px 5px;
            &.input-error{
                color:${Themes.warningLight};
                margin:0px 5px;
                font-size:0.9rem;
            }
           }
           & > input,& > textarea{
               font-family:input;
               width:100%;
               padding:10px;
               border:none;
               margin:10px 5px;
               outline:0;
               min-width:100%;
               background-color:${Themes.bgGrey};
               border-radius:25px;
           }
           & > textarea{
               resize:none;
               height:200px;
               border-radius:10px;
           }
           .size-container{
               display:flex;
               & > div{
                   padding:5px;
               }
           }
       }
    }
    img{
        width:100%;
        max-width:300px;
        max-height:300px;
        aspect-ratio:1/1;
        object-fit:cover;
    }
    & > div:last-child{
        display:flex;
        padding:10px;
        button{
            margin:auto;
            margin-top:20px;
            width:110px;
            border-radius:170px;
            height:30px;
            img{
                opacity:0.8;
                height:100%;
                padding:3px;
            }
        }
    }
  }

`
