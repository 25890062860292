import React,{useState} from 'react'
import { StyledSignIn } from './SignIn.styled'
import {login} from "../../firebase";
import {AuthInput , AuthButton} from "../../components";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {setCurrentAuthPage} from "../../redux/reducer/app.reducer";
export default function SignIn() {
  const dispatch = useDispatch();
const [email,setEmail] = useState("");
const [password,setPassword] = useState("");
const [loading ,setLoading] = useState(false);
const [subError ,setSubError] = useState("");
const navigator = useNavigate();
const handleLogin = async (e)=>{
  e.preventDefault();
  try {
      setLoading(true);
      navigator("/");
      await login(email,password);
      setLoading(false);
  } catch (e) {
    setSubError(e.toString().split(":")[2]);
    setLoading(false);
  }
}
const handleEmailInput = (val)=>{
   setEmail(val);
}
const handlePasswordInput = (val)=>{
  setPassword(val);
}
return (
 <StyledSignIn> 
    <img src="/assets/account.png" alt="" />
    <h1>Sign in</h1>
    <span>{subError}</span>
    <form onSubmit={handleLogin}>
            <AuthInput type="email" required={true} placeholder="Email" disabled={loading} error="" handleInput={handleEmailInput } />
            <AuthInput type="password" required={true} placeholder="Password" disabled={loading} error="" handleInput={handlePasswordInput} />
            <span><span onClick={()=>dispatch(setCurrentAuthPage(3))}>Forgot Password?</span>&nbsp;&nbsp;&nbsp;&nbsp;<span>New User? <b onClick={()=>dispatch(setCurrentAuthPage(2))}>Sign Up</b></span></span>
            <AuthButton disabled={loading} label="Login" />
    </form>
    
 </StyledSignIn> 
)
}
