import React, { useState } from 'react'
import {AuthInput,AuthButton} from '../';
import { StyledResetAccount } from './ResetAccount.styled';
import validator, {emailValiditor} from "../../services/validator";
import {resetPassword} from "../../firebase";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCurrentAuthPage } from '../../redux/reducer/app.reducer';

export default function ResetAccount() {
const [btnLoading,setBtnLoading] = useState(false);
const [email,setEmail] = useState({value:"",isValid:false,error:""})
const navigate = useNavigate();
const dispatch = useDispatch();
const handleEmailInput  = (val)=>{
  const {message,result} = validator(val,[emailValiditor]);
  setEmail({value:val,isValid:result,error:message});
}
const handelReset = async (e)=>{
   e.preventDefault();
   setBtnLoading(true);
   if(email.isValid){
       try{
        await resetPassword(email.value);
        navigate("/message_reset");
        dispatch(setCurrentAuthPage(0));
       }catch(e){
        setEmail({...email,error:e.toString().split(":")[2]});
        setBtnLoading(false);
       }
  
   }
}
return (
 <StyledResetAccount> 
    <h1>Reset Password</h1>
    <form onSubmit={handelReset}>
            <AuthInput type="email" required={true} placeholder="Email" disabled={btnLoading} error={email.error} handleInput={handleEmailInput } />
            <AuthButton disabled={btnLoading} label="Reset" />
    </form>  
 </StyledResetAccount> 
)
}
