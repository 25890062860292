import styled from "styled-components";
import Themes from "../../style/theme";
export const StyledAboutUs = styled.div`
    background-color:white;
    display:flex;
    flex-direction:column;
    align-items:center;
     h1{
         margin:40px 5px;
         padding:5px;
         border-radius:15px;
          width:100%;
          max-width:800px;
          text-align:center;
          background-color:rgb(236 236 236 / 0.52);
          font-family:Segoe UI;
          font-weight:100;
          color:${Themes.darkBack};
     }
     p{
         text-align:center ;
         width:100%;
         max-width:1000px;
         color:${Themes.darkBack};
         margin-bottom:20px;
     }
     & > div{
         display:flex;
         width:100%;
         justify-content:center;
         flex-wrap:wrap;
         box-sizing:border-box;
         margin-bottom:20px;
         img{
             width:100%;
             flex:1;
             margin:5px;
             max-width:300px;
             box-sizing:border-box;
         }
     }

`
