import { ArrowDropDown } from '@mui/icons-material'
import React,{useState,useEffect} from 'react'
import { StyledCustomeDropdown,StyledCoverBG } from './CustomeDropdown.styled'
export default function CustomeDropdown({items = [],dark=false,selected = 0,handleSelected = (i)=>{}}) {
const [isActive ,setIsActive] = useState(false);
const [showCoverBg,setShowCoverBg] = useState(false);
const handleCoverClick  = (e)=>{
    setIsActive(false);
}
useEffect(()=>{
    if(isActive) setShowCoverBg(true);
    else setShowCoverBg(false);
},[isActive])
return (
 <>
  {showCoverBg ?  <StyledCoverBG onClick={handleCoverClick}></StyledCoverBG> : null}
  <StyledCustomeDropdown className={`${dark ? "dark" : ""}`}> 
    <span onClick={()=>setIsActive(!isActive)}>{(
        ((!isNaN(Number(selected)))&&items.length > 0) ?
         ((Number(selected) < 0) ? "Select..." : items[Number(selected)])  :  "Select...")} <ArrowDropDown /></span>
    <div className={`${isActive ? "active-custom-dropdown" : ""}`}>
        {items.map((s,i)=><span key={i} onClick={()=>{handleSelected(i);setIsActive(false);}}>{s}</span>)}
    </div>
  </StyledCustomeDropdown> 
 </>

)
}
