import axios from "axios";
axios.defaults.baseURL = "/";
export const Requests = {
      getProduct:async (id)=>  axios.get(`/api/product/${id}`),
      getAllProducts:async ({gender="",size="",sort="",page=1,key=""})=>  axios.get(`/api/product?gender=${gender}&size=${size}&sort=${sort}&key=${key}&page=${page}`),
      addToCart:async (body,token)=>  axios.post("/api/cart",body,{
        headers: {
          'authorization': `Barear ${token}`
        }
      }),
      getCarts: async (token)=>  axios.get("/api/cart",{
        headers:{
          'authorization': `Barear ${token}`
        }
      }),
      deleteCart: async (id,token)=>  axios.delete(`/api/cart?id=${id}`,{
        headers:{
          'authorization': `Barear ${token}`
        }
      }),

      addToOrder:async (body,token)=>  axios.post("/api/order",body,{
        headers: {
          'authorization': `Barear ${token}`
        }
      }),
      deleteOrder:async (id,token)=>  axios.delete(`/api/admin/order/delete?id=${id}`,{
        headers: {
          'authorization': `Barear ${token}`
        }
      }),
      deleteProduct:async (id,token)=>  axios.delete(`/api/admin/product/delete?id=${id}`,{
        headers: {
          'authorization': `Barear ${token}`
        }
      }),
      getAllAdminProduct:async(token)=> axios.get("/api/admin/product",{
        headers: {
          'authorization': `Barear ${token}`
        }
      }),
      addProduct: async (body,token)=>  axios.post("/api/admin/product/post",body,{
        headers: {
          'authorization': `Barear ${token}`
        }
      }),
      getAllOrder:async (token)=>  axios.get("/api/admin/order/getorders",{
        headers: {
          'authorization': `Barear ${token}`
        }
      }),
      enableUser:async (id,token)=>  axios.put(`/api/admin/user/enable?id=${id}`,{},{
        headers: {
          'authorization': `Barear ${token}`
        }
      }),
      disableuser:async (id,token)=>  axios.put(`/api/admin/user/disable?id=${id}`,{},{
        headers: {
          'authorization': `Barear ${token}`
        }
      }),
      deleteUser:async (id,token)=>  axios.delete(`/api/admin/user/delete?id=${id}`,{
        headers: {
          'authorization': `Barear ${token}`
        }
      }),
      getAllUser:async (token)=>  axios.get("/api/admin/user",{
        headers: {
          'authorization': `Barear ${token}`
        }
      }),
      updateProduct: async (body,token)=>  axios.put("/api/admin/product/update",body,{
        headers: {
          'authorization': `Barear ${token}`
        }
      }),
      getAdminProduct:async (id,token)=>  axios.get(`/api/admin/product/item?id=${id}`,{
        headers: {
          'authorization': `Barear ${token}`
        }
      }),
}