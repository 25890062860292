let Themes = {
     primary:"#FCB43C",
     white:"white",
     bgGrey:"#EEEEEE",
     darkerBlack:"#707070",
     niceBlue:"#4778E1",
     darkBack:"#555555",
     bage:"#3f51b5",
     warningLight:"#FF9671",
     lightGrey:"#F0F0F0",
     niceGrey:"rgb(236 236 236 0.32)",
 }
 export default Themes;
