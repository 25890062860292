import React,{useEffect,useState,useRef} from 'react'
import { StyledProfilePage } from './ProfilePage.styled'
import {AuthButton, UpdateInput} from "../../components"
import validator,{ emailValiditor, nameFieldValidator, passwordValidator } from '../../services/validator';
import { Edit } from '@mui/icons-material';
import { storage, updateUser } from '../../firebase';
import {ref } from "@firebase/storage";
import {useSelector} from "react-redux";

import { getDownloadURL, uploadBytesResumable } from 'firebase/storage';
export default function ProfilePage() {
const [name,setName] = useState({value:"",isValid:false,error:""});
const [email,setEmail] = useState({value:"",isValid:false,error:""});
const [password,setPassword] = useState({value:"",isValid:false,error:""});
const [oldPassword,setOldPassword] = useState({value:"",isValid:false,error:""});
const [confirmPassword,setConfirmPassword] = useState({value:"",isValid:false,error:""});
const [btnLoading,setBtnLoading] = useState(false);
const [profileImg,setProfileImg] = useState(null);
const [imgSrc,setImgSrc] = useState("");
const profileImgView = useRef(null);
const user  = useSelector((state) => state.user.user);
useEffect(()=>{
   if(user){
      setName({value:user.displayName,isValid:true,error:""});
      setEmail({value:user.email,isValid:true,error:""});
      setImgSrc(user.photoURL);
   }
  
},[]);
const handelName = (val)=>{
   const {message,result} = validator(val,[nameFieldValidator]);
   setName({value:val,isValid:result,error:message});
}
const handelEmail = (val)=>{
   const {message,result} = validator(val,[emailValiditor]);
   setEmail({value:val,isValid:result,error:message});
}
const handelPassword = (val)=>{
   const {message,result} = validator(val,[passwordValidator]);
   setPassword({value:val,isValid:result,error:message});
}
const handelOldPassword = (val)=>{
   setOldPassword({value:val,isValid:true,error:""});
}
const handelConfirmPassword = (val)=>{
   if(password.value===val)
   setConfirmPassword({value:val,isValid:false,error:""});
   else  setConfirmPassword({value:val,isValid:true,error:"Password don't match"});
}
const handelSubmit = async (e)=>{
   e.preventDefault();
   if(!user) return;
   if(!(name.isValid&&email.isValid&&password.isValid)){
      handelEmail(email.value)
      handelName(name.value);
      handelPassword(password.value);
      handelConfirmPassword(confirmPassword.value);
      return;
   }
   if(!profileImg){
      setBtnLoading(true);
      try{
         await updateUser(name.value,user.email,oldPassword.value,email.value,password.value,user.photoURL);
         window.location.href = "/";
     }catch(e){
        setBtnLoading(false);
        setOldPassword({...oldPassword,error:"Incorrect Password"})
        console.log(e);
    }
    return;
   }
  
   setBtnLoading(true);
    const storageRef = ref(storage,`/profiles/${user.email.split("@")[0]}`);
    const uploadTask = uploadBytesResumable(storageRef,profileImg);
    uploadTask.on("state_changed",(snapshot)=>{},(err)=>{
        console.log(err);
    },()=>{
       getDownloadURL(uploadTask.snapshot.ref).then(async (url)=>{
          try{
              await updateUser(name.value,user.email,oldPassword.value,email.value,password.value,url);
              window.location.href = "/";
          }catch(e){
             setBtnLoading(false);
             setOldPassword({...oldPassword,error:"Incorrect Password"})
             console.log(e);
         }
       })
    })

}
const loadPic = (e)=>{
   setProfileImg(e.target.files[0]);
   var reader = new FileReader();
   reader.onload = function(){
      setImgSrc(reader.result);
   }
   reader.readAsDataURL(e.target.files[0]);
}
return (
 <StyledProfilePage> 
    <h1>Update Profile </h1>
    <form onSubmit={handelSubmit} >
      <div className='form-container'>
         <div onClick={(e)=>e.stopPropagation()} className='img-contianer'>
            <img onError={(e)=>e.target.src=`/assets/profile.png`} src={imgSrc ?? `/assets/profile.png`} ref={profileImgView} alt="" />
            <Edit  onClick={(e)=>e.target.parentElement.nextElementSibling.click()} />
         </div>
         <input  disabled={btnLoading}  name="profile" onChange={loadPic} type="file" />
         <UpdateInput disabled={btnLoading} val={user.displayName} type="text" label="Full Name" error={name.error} handelOnChange={(e)=>handelName(e.target.value)} />
         <UpdateInput disabled={btnLoading} val={user.email} type="email" label="Email" error={email.error} handelOnChange={(e)=>handelEmail(e.target.value)} />
         <UpdateInput disabled={btnLoading} type="password" label="Old Password" error={oldPassword.error} handelOnChange={(e)=>handelOldPassword(e.target.value)} />
         <UpdateInput disabled={btnLoading} type="password" label="New Password" error={password.error} handelOnChange={(e)=>handelPassword(e.target.value)} />
         <UpdateInput  disabled={btnLoading} type="password" label="Confirm Password" error={confirmPassword.error} handelOnChange={(e)=>handelConfirmPassword(e.target.value)} />
         <AuthButton label="Save changes" disabled={btnLoading} />
      </div>
    </form>
 
 </StyledProfilePage> 
)
}
