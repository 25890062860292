import styled from "styled-components";
import Themes from "../../style/theme";
export const StyledAdminSearchInput = styled.div`
 display:flex;
   input{
        width:100%;
        max-width:300px;
       margin:auto;
       padding:10px;
       border:none;
       background-color:${Themes.bgGrey};
       outline:0;
       border-radius:25px;
   }
`
