import { createSlice } from '@reduxjs/toolkit';
const appSlice = createSlice({
  name: 'app',
  initialState: {
   currentAuthPage:0,
   appStarted:false,
   currentAdminPage:0,
  },
  reducers: {
     setCurrentAuthPage: (state,action) =>{
        state.currentAuthPage = action.payload;
      },
      setCurrentAdminPage: (state,action) =>{
        state.currentAdminPage = action.payload;
      },
      setAppStarted:(state,action) =>{
        state.appStarted = action.payload
      }
  }
})
export const { setCurrentAuthPage,setAppStarted, setCurrentAdminPage } = appSlice.actions;
export default appSlice.reducer;
