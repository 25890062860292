import React from 'react'
import { StyledAuthInput } from './AuthInput.styled'
export default function AuthInput({disabled,type,required,placeholder,error,handleInput}) {
return (
 <StyledAuthInput> 
     <input type={type} autoComplete="none" placeholder={placeholder} required={required} disabled={disabled} onChange={(e)=>handleInput(e.target.value)} />
     <span>{error}</span>
 </StyledAuthInput> 
)
}
