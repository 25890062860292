import React,{useEffect,useState} from 'react'
import { Delete, Edit } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { AdminSearchInput, CustomTable } from '../../components';
import { setCurrentAdminPage } from '../../redux/reducer/app.reducer';
import { Requests } from '../../services/requests';
export default function AdminOrders() {
    const user = useSelector(state=>state.user.user);
    const [isLoading , setIsLoading] = useState(true);
    const [currentData,setCurrentData] = useState([]);
    const [searchKey,setSearchKey] = useState("");
    const dispatch = useDispatch();
    const theader = [
        "Customer Email",
        "Customer Phone",
        "Product Name",
        "Size",
        "Image",
        "Price",
        "Quantity",
        "Created At",
        "Updated At"
    ]
    const actionButtons = [
        {action:Delete,handler:async (id)=>{
            console.log("called");
          const waitingData = currentData.map(d=>{
              if(d._id===id) return {...d,disabled:true};
              else return d;
          });
          setCurrentData(waitingData);
          await Requests.deleteOrder(id,user.accessToken);
          setCurrentData(currentData.filter(d=>d._id!=id))
        }}
    ]
    useEffect(async ()=>{
      dispatch(setCurrentAdminPage(0));
      let res= {data:[]};
      try{
        res = await Requests.getAllOrder(user.accessToken);
      }catch(e){
          console.log("failed");
      }
      const data = res.data.map(d=>{
         return {
             _id:d._id,
             customerEmail:d.customer,
             customerPhone:d.phone,
             productName:d.product.name,
             size:d.product.size,
             imgUrl:d.product.imgUrl,
             price:d.product.price.toLocaleString()+" ETB",
             quantity:d.product.quantity,
             createdAt:d.createdAt,
             updatedAt:d.updatedAt,
             disabled:false
             }
        });
            setCurrentData(data);
            setIsLoading(false);
    },[]) 
    const searchHandler = (key)=>{
        setSearchKey(key);
    }     
    return (
     <> 
      <AdminSearchInput handler={searchHandler} placeholder="Search by date" />
      <CustomTable searchBy='createdAt' searchKey={searchKey} theader={theader} actionButtons={actionButtons} isLoading={isLoading} data={currentData} />
     </> 
    )
}
