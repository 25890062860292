import React,{useState,useEffect} from 'react'
import { AuthButton } from '../../components'
import { Requests } from '../../services/requests';
import { StyledCartPage } from './CartPage.styled';
import {useSelector,useDispatch} from "react-redux";
import {updateCartCount} from "../../redux/reducer/user.reducer";
import {useNavigate} from "react-router-dom";
import { verifyEmail } from '../../firebase';
export default function CartPage() {
const [btnLoading,setBtnLoading] = useState(false);
const [cartsLoading,setCartsLoading] = useState(true);
const [carts,setCarts] = useState([]);
const navigator = useNavigate();
const dispatch = useDispatch();
const user  = useSelector((state) => state.user.user);
useEffect(()=>{
    if(user&&user.accessToken)
        Requests.getCarts(user.accessToken).then((res)=>{
            if(typeof res.data==="object") setCarts(res.data);
            setCartsLoading(false);
        }).catch((e)=>{
            setCartsLoading(false);
        });
    else navigator("/");
},[]);
useEffect(()=>{
    if(user&&user.accessToken){
        Requests.getCarts(user.accessToken).then(count=>{
          dispatch(updateCartCount(count.data.length));
        }).catch(e=>{
          dispatch(updateCartCount(0));
        })
      }
});
const checkoutHandler = async ()=>{
    if(!user.emailVerified) {
        verifyEmail();
        navigator("/message_verify_again");
        return;
   }
         setBtnLoading(true);
         if(user&&user.accessToken)
            try{
                 await Requests.addToOrder(carts.map(cart=>{ return {customer:cart.cartOwner,product:{...cart.product,price:cart.product.price*cart.product.quantity}};}),user.accessToken);
                 dispatch(updateCartCount());
                 navigator("/message_checkout");
              }catch(e){
                window.location.href =  window.location.href;
                     setBtnLoading(false);
              }
}
const handleRemoveFromCart = (id,e)=>{
    if(!user.emailVerified) {
        verifyEmail();
        navigator("/message_verify_again");
        return;
   }
       e.target.disabled = true;
       e.target.innerText = "Removing....";
       if(user&&user.accessToken)
       Requests.deleteCart(id,user.accessToken).then(res=>{
            setCarts(c=>c.filter(c=>c._id!=id));
       }).catch(e=>{
           e.target.disabled=false;
           window.location.href =  window.location.href;
           e.target.innerText = "Remove from cart";
        });
}
return (
 <StyledCartPage> 
   {
       cartsLoading ?   <object data="/assets/loading.svg"> </object> :
   (<>
   <div>
     {
         carts.length === 0 ? <span>Noting on your cart</span>:
         carts.map(c=><div key={c._id} className='cart-container'>
         <div>
             <object data="/assets/loading.svg"> </object>
             <img hidden={true} onError={(e)=>{e.target.hidden=false;e.target.previousElementSibling.remove();}} onLoad={(e)=>{e.target.hidden=false;e.target.previousElementSibling.remove();}} src={`${c.product.imgUrl}`} alt="" />
         </div>
         <div>
                     <h3>{c.product.name}</h3>
                     <p>{c.product.description}</p>
                     <div>
                         <h4>Quantity -{'\u00A0'}</h4>
                         <div><span>{c.product.quantity}</span></div>   
                     </div>
                     <div>
                         <h4>Size - {'\u00A0'}</h4>
                         <div><span>{c.product.size}</span></div>   
                     </div>
                     <span><b>{(c.product.price*c.product.quantity).toLocaleString()} ETB</b></span>
                     <AuthButton type="button"  onClick={(e)=>handleRemoveFromCart(c._id,e)}  label="Remove from Cart" />
         </div>
      </div>)
     }
   
</div>
{carts.length > 0 ?
<div className='checkout-container'>
   <div><h1>ORDER SUMMARY</h1></div>
   <div><span><b>Total</b></span><span><b>{carts.reduce((total,c)=>total+c.product.price*c.product.quantity,0).toLocaleString()} ETB</b></span></div>
   <AuthButton onClick={()=>checkoutHandler()} type="button" disabled={btnLoading} label="Checkout" />
</div> : null
}
</>
)}
 </StyledCartPage> 
)
}
