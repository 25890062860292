import styled from "styled-components";
import Themes from "../../style/theme";
export const StyledHome = styled.div`
   width: 100%;
   overflow: hidden;
   display:flex;
   flex-direction:column;
   padding-bottom:20px;
   & > button{
       margin:auto !important;
   }
   & > h1{
       text-align: center;
       color: ${Themes.darkBack};
       margin-bottom:20px;
   }
   & > main{
       width:100%;
       min-height:350px;
       position:relative;
       & > img{
           width:100%;
           zoom:0.9;
       }
       .main-content{
           top:0;
           bottom:0;
           position:absolute;
           width:100%;
           box-sizing:border-box;
           padding:5px;
           display:flex;
           justify-content:center;
           align-items:center;
           background-color:rgb(0 0 0 / 0.2) ;
           & > div{
               flex:1;
               margin:0px 10%;
               color:${Themes.white};
               font-family:Segoe UI;
               h1{
                   font-size:3rem;
               }
               p{
                   font-size:1rem;
               }
               button{
                   border-radius:115px;
                   padding:5px 30px;
                   margin-top:50px;
                   width:auto;
                   font-size:1rem;
               }
           }
       }
   }
   & > div{
       display: flex;
       justify-content: space-evenly;
       flex-wrap: wrap;
       margin: 50px 0px;
       & > .catagorie:hover > span > span{
         width:100%;
       }
       & > .catagorie:hover > div{
        background-color:rgb(0 0 0 / 0.3);
       }
       & > .catagorie{
         margin:5px;
         flex:1;
          max-width: 400px;
          aspect-ratio:1.5/1;
          background-color: ${Themes.primary};
        
          &:first-child{
            background-image: url("/assets/male.jpg");
            background-size: 100% auto;
          }
          &:last-child{
            background-image: url("/assets/female.jpg");
          }
        
          background-size: 100% auto;
          display: flex;
          position:relative;
          & > div{
              position:absolute;
              width:100%;
              height:100%;
              z-index:1;
              transition:background-color 0.3s;
          }
          & > span{
              color:${Themes.white};
              padding:4px;
              margin:10px;
              font-size: 2rem;
              width:auto;
              height:auto;
              align-self:flex-start;
              padding-bottom:0px !important;
              font-weight:bold;
              -webkit-text-stroke: 0.1px black;
              display:flex ;
              z-index:3 ;
              flex-direction:column ;
              & > span{
                  width:0%;
                  height:3px;
                  border-radius:20px;
                  background-color:${Themes.white};
                  transition:width 0.3s;
              }
          }
       }
   }
   @media only screen and (max-width: 600px) {
    & > main{
        min-height:auto;
    }
    .catagorie > span {
         font-size:1.2rem !important;
       }
       .main-content{
                & > div{
                        h1{
                            font-size:6vw !important;
                        }
                        p{
                            font-size:3.5vw !important;
                        }
                        button{
                            font-size:3.5vw !important;
                        }
                }
            }
   }
  
`
