import styled from "styled-components";
import Themes, { Theme } from "../../style/theme";
export const StyledProductsView = styled.div`
  display: grid !important;
  grid-template-columns: repeat(auto-fit,minmax(200px ,1fr));
  justify-content:center;
  justify-items: center;
  align-content:center;
  align-items:center;
  grid-gap: 10px;
  padding:5px;
  width:100%;
  max-width:1200px;
  box-sizing: border-box;
  margin:auto !important;
  &.loading{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & > div{
       display: flex;
       flex-direction: column;
       width: 100%;
       max-width: 200px;
       margin:0 !important;
       aspect-ratio: 1 / 1.4 !important; 
       overflow:hidden; 

       & > div:first-child{
           flex:1;
           width: 100%;
           min-height: 100px;
           overflow: hidden;
           background-repeat: no-repeat;
           background-image:linear-gradient(#FBEAFF,#FBEAFF);
    &.loaded-container{
      background-image: linear-gradient(transparent,transparent);
    }
           & > img{
             height: 100%;
             visibility: hidden;
             transition:all 0.5s;
             &:hover{
               cursor: pointer;
               transform: scale(1.1);
             }
             &.loaded{
               visibility: visible;
             } 
           }
       }
       & > div:last-child{
         display: flex;
         flex-direction: column;
         padding:10px 0px;
         & > span:first-child{
           color:${Themes.darkBack};
           font-weight: 500;
           opacity: 1;
         }
         & > span:last-child{
           color:${Themes.darkBack};
           opacity: 0.8;
         }
       }
  }
  @media only screen and (max-width: 500px) {
    grid-template-columns: repeat(1,1fr);
    & > div{
      max-width:300px;
      aspect-ratio: auto !important; 
      & > div:first-child{
      & > img{
             width:100%;
             height: auto;
      }
    }
    }
  }
`
