import { Delete, Edit } from '@mui/icons-material';
import React,{useEffect,useState} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AdminSearchInput, CustomTable } from '../../components';
import { setCurrentAdminPage } from '../../redux/reducer/app.reducer';
import { Requests } from '../../services/requests';
export default function AdminProducts() {
const user = useSelector(state=>state.user.user);
const [isLoading , setIsLoading] = useState(true);
const [currentData,setCurrentData] = useState([]);
const [searchKey,setSearchKey] = useState("");
const navigator = useNavigate();
const dispatch = useDispatch();
const theader = [
    "Design Name",
    "Gender",
    "Size",
    "Image",
    "Price",
    "Created At",
    "Updated At"
]
const actionButtons = [
    {action:Edit,handler:(id)=>{
          navigator(`/edititem/${id}`);
    }},
    {action:Delete,handler:async (id)=>{
      const waitingData = currentData.map(d=>{
          if(d._id===id) return {...d,disabled:true};
          else return d;
      });
      setCurrentData(waitingData);
      await Requests.deleteProduct(id,user.accessToken);
      setCurrentData(currentData.filter(d=>d._id!=id))
    }}
]
useEffect(async ()=>{
  dispatch(setCurrentAdminPage(2));
  let res= {data:[]};
  try{
    res =  await Requests.getAllAdminProduct(user.accessToken);
  }catch(e){
      console.log("failed");
  }
  const data = res.data.map(d=>{
      const gender = d.gender;
      const productName = d.productName;
      delete d.description;
      delete d.productName; 
      delete d.__v;delete d.gender;
     return {
         productName,gender,...d,
         price:d.price.toLocaleString()+" ETB",disabled:false}});
 
setCurrentData(data)
setIsLoading(false);
},[])      
useEffect(()=>{
  console.log(currentData);
},[currentData]);
const handleSearch = (key)=>{
   setSearchKey(key);
}
return (
 <> 
  <AdminSearchInput placeholder='Search Products....' handler={handleSearch} />
  <CustomTable searchBy='productName' searchKey={searchKey} theader={theader} actionButtons={actionButtons} isLoading={isLoading} data={currentData} />
 </> 
)
}
