import React from 'react'
import { StyledFooter } from './Footer.styled'
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import PinterestIcon from '@mui/icons-material/Pinterest';
export default function Footer() {
return (
 <StyledFooter id="contact"> 
     <div>
       
        <div>
        <h1><img src='/assets/logo.png'/></h1>
        
            <span>we are a young company always looking for new and creative ideas to help you with our products in your needs</span>
    
        </div>

        <div>
            <h1>Catagories</h1>
            <span>Women</span>
            <span>Men</span>
        </div>
        <div>
        <h1>Get In touch</h1>
            <span>Any questions? Let us know in shiro meda, Addis Abeba, Ethiopia
         or Call us on (+251) 911 ****** </span>
        </div>
     </div>
     <div className='social-cont'>
         <InstagramIcon/>
         <FacebookIcon/>
         <TwitterIcon/>
         <PinterestIcon/>
     </div>
     <div><span>Copyright &copy;2022 All right reserved</span></div>
 </StyledFooter> 
)
}
