import styled from "styled-components";
import Themes from "../../style/theme";
export const StyledShowMessage = styled.div`
  display: flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  background-color:${Themes.white} ;
  max-width:700px;
  margin:20px auto;
  flex:1;
  padding:20px;
  border-radius:10px;
  box-shadow:1px 1px 3px rgb(0 0 0 / 0.3);
  & > *{
      margin:5px 0px;
      color:${Themes.darkBack}
  }
  & > svg{
      background-color:${Themes.primary};
      color:${Themes.white};
      border-radius:50%;
      padding:5px;
      transform:scale(1.5);
  }
  a{
      text-decoration:none;
  }
`
