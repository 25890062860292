import React,{useEffect,useState} from 'react'
import { Block, CheckCircle, Delete, DisabledByDefault, Edit } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AdminSearchInput, CustomTable } from '../../components';
import { setCurrentAdminPage } from '../../redux/reducer/app.reducer';
import { Requests } from '../../services/requests';
export default function AdminUsers() {
    const user = useSelector(state=>state.user.user);
    const [isLoading , setIsLoading] = useState(true);
    const [currentData,setCurrentData] = useState([]);
    const [searchKey , setSearchKey] = useState("");
    const dispatch = useDispatch();
    const theader = [
        "Name",
        "Email",
        "Phone",
        "Profile Image",
        "Email Verifed",
        "Active Status",
    ]
    const actionButtons = [
        {action:CheckCircle,handler:async (id)=>{
            const waitingData = currentData.map(d=>{
                if(d._id===id) return {...d,disabled:true};
                else return d;
            });
            setCurrentData(waitingData);
            await Requests.enableUser(id,user.accessToken);
            const activeStatus = currentData.find(d=>d._id==id).activeStatus;
            const updatedData = currentData.map(d=>{
                if(d._id===id) return {...d,activeStatus:!activeStatus,disabled:false};
                else return d;
            });
          setCurrentData(updatedData)
        }},
        {action:Block,handler:async (id)=>{
            const waitingData = currentData.map(d=>{
                if(d._id===id) return {...d,disabled:true};
                else return d;
            });
            setCurrentData(waitingData);
            await Requests.disableuser(id,user.accessToken);
            const activeStatus = currentData.find(d=>d._id==id).activeStatus;
            const updatedData = currentData.map(d=>{
                if(d._id===id) return {...d,activeStatus:!activeStatus,disabled:false};
                else return d;
            });
          setCurrentData(updatedData)
        }},
        {action:Delete,handler:async (id)=>{
          const waitingData = currentData.map(d=>{
              if(d._id===id) return {...d,disabled:true};
              else return d;
          });
          setCurrentData(waitingData);
          await Requests.deleteUser(id,user.accessToken);
          setCurrentData(currentData.filter(d=>d._id!=id))
        }}
    ]
    useEffect(async ()=>{
      dispatch(setCurrentAdminPage(3));
      let res= {data:[]};
      try{
        res = await Requests.getAllUser(user.accessToken);
      }catch(e){
          console.log("failed");
      }
      console.log(res.data);
      const data = res.data.map(d=>{
         const activeStatus = d.disabled;
         delete d.disabled;
         return {
             _id:d.uid,
             displayName:d.displayName,
             email:d.email,
             phone:d.phoneNumber,
             imgUrl:d.photoURL,
             emailVerifed:d.emailVerified ? "Verfied" : "UnVerfied",
             activeStatus:activeStatus,
             disabled:false
             }
        });
            setCurrentData(data);
            setIsLoading(false);
    },[])   
    const searchHandler = (key)=>{
      setSearchKey(key);
    }   
    return (
     <> 
      <AdminSearchInput handler={searchHandler} placeholder='Search by phone' />
      <CustomTable searchBy='phone' searchKey={searchKey} theader={theader} actionButtons={actionButtons} isLoading={isLoading} data={currentData} />
     </> 
    )
}
