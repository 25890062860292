import styled from "styled-components";
import Themes from "../../style/theme";
export const StyledSignIn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    & > img{
        width:100px;
    }
    & > span{
       margin-bottom:10px;
       color:${Themes.warningLight};
       align-self: flex-start;
       font-size: 0.9rem;
    }
    h1{
       font-weight: 100;
       margin-bottom: 30px;
   }
    form{
        width:100%;
        display: flex;
        flex-direction: column;
        & > span{
            font-weight: 100;
            font-size: 0.8rem;
            margin:30px auto;
            margin-top: 10px;
            display:flex;
            justify-content:space-between;
            flex-wrap:wrap;
            width:100%;
            b{
                cursor: pointer;
                font-weight:400;
            }
            & > span{
               margin-bottom:5px;
               &:first-child{
                   cursor: pointer;
               }
            }
        }
    }
`
