import React,{useEffect,useState} from 'react'
import { StyledHome } from './Home.styled'
import { AuthButton, ProductsView } from '../../components';
import { Requests } from '../../services/requests';
import {useNavigate} from "react-router-dom";
export default function Home() {
const [products,setProducts] = useState([]);   
const [showSeeMore,setShowSeeMore] = useState(false);
const [currentPage,setCurrentPage] = useState(1);
const [loadingProducts,setLoadingProducts] = useState(true);
const navigate = useNavigate();   
useEffect(async ()=>{
  loadProducts();
},[]);
const loadProducts = ()=>{
      setLoadingProducts(true);
      if(currentPage!=-1){
            setShowSeeMore(false);
            Requests.getAllProducts({page:currentPage}).then(res=>{
             if(res.data.length == 0){
                  setCurrentPage(-1);
                  setShowSeeMore(false);
             }else{
                  setProducts([...products,...res.data]);
                  Requests.getAllProducts({page:currentPage+1}).then(res2=>{
                        if(res2.data.length == 0){
                              setCurrentPage(-1);
                              setShowSeeMore(false);
                        }else{
                              setShowSeeMore(true);
                              setCurrentPage(currentPage+1);
                        }
                  }).catch((e)=>{
                    console.log(e); 
                  })
             }
             setLoadingProducts(false);
           
       }).catch(e=>{
             setLoadingProducts(false);
       });
      } else   setLoadingProducts(false);
}
return (
 <StyledHome> 
      <main>
       <img id="bg-main" src="/assets/bg.jpg" alt="" />  
       <div className="main-content">
             <div>
               <h1>Discover Products</h1>
               <p>To make life easier, in Emilka shop you will find amazing products</p>
               <a href="#products"><AuthButton label="SHOP NOW" /></a>
             </div>
           
       </div>
      </main>
      <div>
            <div  onClick={()=>navigate("/products/men")}  className='catagorie'>
                  <div></div>
                  <span>Men<span></span></span>
           </div>
            <div  onClick={()=>navigate("/products/women")} className='catagorie'> <div></div><span>Women<span></span></span></div>
       </div> 
       <h1 id="products">Product Overview</h1>
       <ProductsView products={products} isLoading={loadingProducts} /> 
      {showSeeMore ? <AuthButton onClick={loadProducts} label="See more" /> : null}
 </StyledHome> 
)
}
