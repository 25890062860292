import React from 'react'
import { StyledProductsView } from './ProductsView.styled'
import {useNavigate} from "react-router-dom";
export default function ProductsView({products = [],isLoading = false}) {
const navigate = useNavigate();
const handleImgLoad = (e)=>{
   e.target.classList.add("loaded");
   e.target.parentElement.classList.add("loaded-container");
}
return (
 <StyledProductsView className={`${isLoading ? "loading" : ""}`}> 
    
     {products.map(p=>  
         <div key={p._id}>
            <div onClick={()=>navigate("/product/"+p._id)} >
                <img onLoad={handleImgLoad} onError={handleImgLoad} src={p.imgUrl} alt="" />
            </div>
            <div>
              <span>{p.productName}</span>
              <span>{p.price.toLocaleString()} ETB</span>
            </div>
        </div>)
     }
    { isLoading ? 
       <object data="/assets/loading.svg"> </object>
     :
       ((products.length === 0)? <span>No products found</span> : null)
     }

 </StyledProductsView> 
)
}
