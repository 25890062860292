import styled from "styled-components";
import Themes from "../../style/theme";
export const StyledSignUP = styled.div`
   display: flex;
    flex-direction: column;
    align-items: center;
    & > span{
       margin-bottom:10px;
       color:${Themes.warningLight};
       align-self: flex-start;
    }
    h1{
       font-weight: 100;
       margin-bottom: 30px;
   }
   & > img{
       width:100px;
   }
    form{
        width:100%;
        display: flex;
        flex-direction: column;
        box-sizing:border-box ;
        & > span{
            font-weight: 100;
            font-size: 0.8rem;
            margin:30px auto;
            margin-top: 10px;
            b{
                cursor: pointer;
                font-weight:400;
            }
        }
        #rc-anchor-container{
            width:100% !important;
            box-sizing:border-box ;
            overflow:hidden;
            display:flex !important;
            flex-wrap:wrap !important;
            flex-direction:column !important;
            & > div{
                box-sizing:border-box;
                display:flex;
                flex-wrap:wrap;
                flex-direction:column;
            }
        }
    }
`
