import Global from "./style/globals";
import {Home, ProductsPage,ProductPage,AdminProducts,CartPage,ProfilePage, ShowMessage, AboutUs, PageNotFound, NewProductPage, EditProductPage, OrderPage, UsersPage, ViewItemPage, AdminOrders, AdminUsers, AdminAddProduct, AdminEditProduct} from "./pages";
import {onAuthStateChanged} from "firebase/auth";
import {
 BrowserRouter,
 Routes,
 Route
} from "react-router-dom";
import {setUser} from "./redux/reducer/user.reducer";
import { setAppStarted } from './redux/reducer/app.reducer';
import { updateCartCount } from './redux/reducer/user.reducer';
import {setCurrentAuthPage} from "./redux/reducer/app.reducer";
import {useDispatch, useSelector} from "react-redux";
import {AdminLayout,  CustomTable,  Footer, NavBar} from "./components";
import {auth} from "./firebase";
import { Requests } from "./services/requests";
import PrivateRoute from "./components/PrivatRoute";  
function App() {
const dispath = useDispatch();
const user  = useSelector((state) => state.user.user);
const appStarted  = useSelector((state) => state.app.appStarted);
if(!appStarted)
onAuthStateChanged(auth,async userChanged =>{
   if(!userChanged) dispath(setUser(null));
   else{
    const result = await userChanged.getIdTokenResult();
    dispath(setUser(userChanged.email && {
      userId:userChanged.uid,
      accessToken:userChanged.stsTokenManager.accessToken,
      email:userChanged.email,
      emailVerified:userChanged.emailVerified,
      displayName:userChanged.displayName,
      photoURL:userChanged.photoURL,
      isAdmin:result.claims.admin
     }));
   } 
   dispath(setCurrentAuthPage(0));
   if(userChanged&&userChanged.email&&userChanged.accessToken){
      Requests.getCarts(userChanged.accessToken).then(count=>{
        dispath(updateCartCount(count.data.length));
      }).catch(e=>{
        dispath(updateCartCount(0));
      })
    }
    if(!appStarted) dispath(setAppStarted(true));
});
 return (
   <>
    <Global/>
     {
          user&&user.isAdmin ? 
          <BrowserRouter>
          <AdminLayout>
            <Routes>
              <Route path="/" exact element={<AdminOrders />}></Route>
              <Route path="/products" exact element={<AdminProducts />}></Route>
              <Route path="/users" exact element={<AdminUsers />}></Route>
              <Route path="/edititem/:id" exact element={<AdminEditProduct />}></Route>
              <Route path="/addproduct" exact element={<AdminAddProduct />}></Route>
              <Route path="*" exact element={<PageNotFound />}></Route>
            </Routes>
          </AdminLayout>
        </BrowserRouter> :
          <>
          <BrowserRouter>
          <NavBar />
          <div id="inner-root">
              <Routes>
                  <Route exact path="/" element={<Home />} />
                  <Route exact path="/aboutus" element={<AboutUs />} />
                  <Route exact path="/products/men" element={<ProductsPage type="men" />} />
                  <Route exact path="/products/women" element={<ProductsPage type="women" />} />
                  <Route exact path="/product/:id" element={<ProductPage />} />
                  <Route exact path="/cart" element={<PrivateRoute><CartPage /></PrivateRoute>} />
                  <Route exact path="/profile" element={<PrivateRoute> <ProfilePage /></PrivateRoute>}  />
                  <Route exact path="/message_verify" element={<ShowMessage title="Registered" message="Verfication email is already sent on your email." />} />
                  <Route exact path="/message_verify_again" element={<ShowMessage title="Email is not verifed" message="Verfication email is already sent on your email." />} />
                  <Route exact path="/message_reset" element={<ShowMessage title="Reset" message="Rest email is already sent on your email." />} />
                  <Route exact path="/message_checkout" element={<ShowMessage title="Checkout Completed" message="we will contact you for delivery of the items." />} />
                  <Route path="*" exact element={<PageNotFound />}></Route>
                </Routes>
          </div>
      </BrowserRouter>
      <iframe id="location" width="100%" height="210" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3940.284563932175!2d38.75753171414963!3d9.037785791341323!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b8fbfe61aecf5%3A0x9b00c20e65c24bff!2sShero%20meda!5e0!3m2!1sen!2set!4v1646253617123!5m2!1sen!2set"  frameBorder="0" scrolling="no"  title="our location"></iframe>
      <Footer />
      </>
        }
      

   </>
 );
}
export default App;
