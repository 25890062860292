import React,{useState,useEffect,useRef} from 'react'
import { StyledNavBar } from './NavBar.styled'
import { useSelector ,useDispatch } from 'react-redux';
import {ShoppingCartOutlined,Menu,Close} from "@mui/icons-material";
import { SignUP,SignIn,ResetAccount } from '..';
import { setCurrentAuthPage } from '../../redux/reducer/app.reducer';
import {logout} from "../../firebase";
import { Link,useLocation} from 'react-router-dom';
export default function NavBar() {
   const [isNavActive,setIsNavActive] = useState(false);
   const [isProfileDropdown,setIsProfileDropDown] = useState(false);
   const currentAuthPage  = useSelector((state) => state.app.currentAuthPage);
   const user  = useSelector((state) => state.user.user);
   const appStarted  = useSelector((state) => state.app.appStarted);
   const cartCount  = useSelector((state) => state.user.cartCount);
   const docBlur = useRef(null);
   const docAuthContainer = useRef(null);
   const dispath = useDispatch();
   const location = useLocation();
   useEffect(()=>{
     window.onresize = window.onscroll = ()=>{
          if(!(docAuthContainer&&docBlur)) return;
          if(!(docAuthContainer.current&&docBlur.current)) return;
          if(document.getElementById("bg-main")){
            docBlur.current.style.opacity= "1";
            const x = document.getElementById("bg-main").getBoundingClientRect();
            const aCont = docAuthContainer.current.getBoundingClientRect();
            const mValue = x.y - aCont.y;
            docBlur.current.style.left = "-"+aCont.x+"px";
            docBlur.current.style.top =  "-"+Math.abs(mValue)+"px";
          }else docBlur.current.style.opacity= "0";
     }   
   },[]);
   useEffect(()=>{
    (function(){
      if(document.getElementById("bg-main")&&docBlur&&docAuthContainer.current){
        docBlur.current.style.opacity= "1";
        const x = document.getElementById("bg-main").getBoundingClientRect();
        const aCont = docAuthContainer.current.getBoundingClientRect();
        const mValue = x.y - aCont.y;
        docBlur.current.style.left = "-"+aCont.x+"px";
        docBlur.current.style.top =  "-"+Math.abs(mValue)+"px";
      }else if(docBlur&&docBlur.current) docBlur.current.style.opacity= "0";
     })();
   });
  useEffect(()=>{
    window.scrollTo({ top: 0, behavior: 'smooth' });
  },[location]);
   const  handleHiddenBgClicked = ()=>{
      setIsProfileDropDown(false);
   }
   return (
    <StyledNavBar>
      {
        (!user&&(currentAuthPage > 0)) ? 
        <div className='auth-page'  onClick={()=> dispath(setCurrentAuthPage(0))}>
            <div  ref={docAuthContainer} onClick={(e)=>e.stopPropagation()} className='auth-container'>
            <div  ref={docBlur}  className='blur-effect'></div>
            <Close  onClick={()=> dispath(setCurrentAuthPage(0))} />
                {
                    (()=>{
                      switch(currentAuthPage){
                      case 1:
                          return <SignIn />
                      case 2:
                          return  <SignUP />
                      case 3:
                          return <ResetAccount />
                      default:
                        return null
                      }
                    })()
                  }
            </div>
        </div>
        : null
      }
    <Menu className='menu-svg' onClick={()=>setIsNavActive(true)} />
    <span className="logo-container"><Link to="/"><img src="/assets/logo.png" alt="" /></Link></span>
     <div className="nav-links">
        <ul className={`${isNavActive ? "active-nav" : ""}`}>
          <Close onClick={()=>setIsNavActive(false)}/>
          <li onClick={()=>setIsNavActive(false)}><Link to="/">Home</Link></li>
          <li onClick={()=>setIsNavActive(false)}><Link to="/aboutus">About Us</Link></li>
          <li onClick={()=>setIsNavActive(false)}><a href="#location">Our Location</a></li>
          <li onClick={()=>setIsNavActive(false)}><a href="#contact">Contact</a></li>
       </ul>
     </div>
    {appStarted ? (
       !user ? 
          <div className="nav-tools">
            <h5 onClick={()=> dispath(setCurrentAuthPage(1))}>SIGN IN</h5>
            <h5 onClick={()=> dispath(setCurrentAuthPage(2))}>REGISTER</h5>
          </div>
          :
          <div className="nav-tools">
            <div  onClick={()=>setIsProfileDropDown(!isProfileDropdown)} className="avator-container">
               <img src={(user ? (user.photoURL ?? "/assets/profile.png") : "/assets/profile.png")}  alt="" />
               <ul className={`${isProfileDropdown ? "profiledropdown-active" : ""}`}>
                <span>{user.displayName}</span>
                 <li><Link to="/profile">Manage Profile</Link></li>
                 <li onClick={logout}> Logout</li>
               </ul>
            </div>
            <div className='cart-cont'>
              {cartCount > 0 ? <span>{cartCount}</span> : null}
              <Link to="/cart"><ShoppingCartOutlined /></Link>
            </div>
          </div>
     ) : <div></div>}
     <div onClick={handleHiddenBgClicked} className={`hidden-bg ${isProfileDropdown ? "hidden-bg--active" : ""}`}></div>
    </StyledNavBar> 
    )
}
