import styled from "styled-components";
import Themes from "../../style/theme";
export const StyledPageNotFound = styled.div`
 display:flex;
 align-items:center;
 justify-content:center;
 font-family:input;
 font-size:max(4.5vw,1.5rem);
 margin:20px 0px;
 font-weight:bold;
 flex:1;
 color:${Themes.darkBack};
 img{
     width:50px;
 }
`
