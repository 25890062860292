import { Done } from '@mui/icons-material'
import React from 'react'
import {Link} from "react-router-dom"
import { StyledShowMessage } from './ShowMessage.styled'
export default function ShowMessage({title,message}) {
return (
 <StyledShowMessage> 
     <Done />
     <h1>{title}</h1>
     <p>{message} </p>
     <Link to="/">Go to home</Link>
 </StyledShowMessage> 
)
}
