import { createSlice } from '@reduxjs/toolkit';
const userSlice = createSlice({
  name: 'user',
  initialState: {
   user:null,
   cartCount:0
  },
  reducers: {
     setUser: (state,action) =>{
        state.user = action.payload;
      },
      updateCartCount:(state,action)=>{
          state.cartCount = action.payload;
      }
  }
})
export const { setUser,updateCartCount } = userSlice.actions;
export default userSlice.reducer;
