import styled from "styled-components";
import Themes from "../../style/theme";
export const StyledFooter = styled.div`
    min-height: 200px;
    width: 100%;
    background-color: ${Themes.primary};
    display: flex;
    flex-direction: column;
    color:${Themes.white};
    
    .social-cont{
        display: flex;
        justify-content: center;
        svg{
            width:35px;
            height:35px;
            margin:10px;
            border-radius: 50px;
            transition:color 0.3s;
            &:hover{
                color:${Themes.darkBack};
                cursor:pointer;
            }
        }
    }
    & > div{
        flex:1;
        &:first-child{
            display: flex;
            justify-content: space-evenly;
            flex-wrap: wrap;
            & > div{
                margin:5px;
                max-width:300px;
                display: flex;
                flex-direction: column;
                h1{
                    font-size: 1.4rem;
                    margin-bottom: 20px;
                    margin-top:10px;
                    img{
                        width: 100px;
                    }
                }
                span{
                    font-size:0.9rem;
                }
            }
        }
        &:last-child{
            display: flex;
            span{
                font-size: 0.8rem;
                margin: auto;
                margin-bottom: 10px;
            }
        }
    }
`
