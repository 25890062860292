import styled from "styled-components";
import Themes from "../../style/theme";
export const StyledProductPage = styled.div`
display: flex;
justify-content: center;
  & > div{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin:20px 0px;
      & > div{
          margin:0px 20px;
      }
      & > div:first-child{
          max-width: 400px;
          display:flex;
          justify-content:center;
          align-items:center;
          flex-wrap:wrap;
          position:relative;
          cursor:none;
          .img-magnifier-glass {
                box-sizing:border-box;
                position: absolute;
                border: 3px solid #000;
                cursor:none;
                width: 100px;
                height: 100px;
                display:none;
            }
            &:hover > .img-magnifier-glass{
               display:block;
            } 

           img{
              border-radius: 15px;
              width: 100%;
          }
      }
      & > div:last-child{
          align-self: flex-end;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          color:${Themes.darkBack};
          b{
              font-size:1.5rem;
              font-weight:400;
          }
          & > h3{
              font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
              font-weight:400;
              font-size:1.2rem;
          }
          & > p{
              margin: 20px 0px;
              font-family:input;
              font-size:1rem;
          }
          & > button{
              margin:10px 0px;
          }
          & > .size-container{
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              & > h4{
                font-weight:500;
              }
              & > select{
                  padding:5px;
                  border:1px solid ${Themes.darkBack};
                  border-radius: 10px;
                  background-color: ${Themes.white};
                  margin:5px;
              }
          }
          & > .quantity-container{
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              & > h4{
                font-weight:500;
              }
              & > div{
                border:1px solid ${Themes.darkBack};
                margin:5px;
                display: grid;
                background-color: ${Themes.white};
                border-radius: 10px;
                grid-template-columns: auto auto;
                & > span{
                    border-right: 1px solid ${Themes.darkBack};
                    width:50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 1.2rem;
                    grid-row: 1 / 3;
                }
              }

          }

      }
  }
`
