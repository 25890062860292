import React,{useEffect, useState} from 'react'
import {signup,verifyEmail,configRecaptcha,finishSignup, verifyPhones} from "../../firebase";
import {AuthInput , AuthButton} from "../../components";
import {useDispatch} from "react-redux";
import {setCurrentAuthPage} from "../../redux/reducer/app.reducer";
import validator,{ emailValiditor,nameFieldValidator,passwordValidator, phoneValidator } from '../../services/validator';
import { StyledSignUP } from './SignUP.styled'
export default function SignUP() {
    const dispatch = useDispatch();
    const [name,setName] = useState({value:"",isValid:"false",error:""});
    const [email,setEmail] = useState({value:"",isValid:"false",error:""});
    const [phone,setPhone] = useState({value:"",isValid:"false",error:""});
    const [password,setPassword] = useState({value:"",isValid:"false",error:""});
    const [confirmPassword,setConfirmPassword] = useState({value:"",isValid:"false",error:""});
    const [loading ,setLoading] = useState(false);
    const [subError ,setSubError] = useState("");
    const [verifyPhone,setVerifyPhone] = useState(false);
    const [otp,setOtp] = useState({value:"",isValid:false,error:""});
    const [otpLoading,setOtpLoading] = useState(false);
    const handleSignup = async (e)=>{
      e.preventDefault();
      if(name.isValid&&email.isValid&&phone.isValid&&password.isValid&&confirmPassword.isValid){
        window.recaptchaVerifier = configRecaptcha();
        const appVerifier = window.recaptchaVerifier;
        setLoading(true);
        try{
               window.confirmationResult = await signup(phone.value,appVerifier);
               setVerifyPhone(true);
        }catch(e){
          setSubError(e.toString().split(":")[2]);
          setLoading(false);
        }
      }else{
         handleEmailInput(email.value);
         handlePhoneInput(phone.value);
         handlePasswordInput(password.value);
         handleConfirmPasswordInput(confirmPassword.value);
      }
    }
    const handleNameInput = (val)=>{
      const {message,result} = validator(val ,[nameFieldValidator]);
      setName({value:val,isValid:result,error:message});
   }
    const handleEmailInput = (val)=>{
       const {message,result} = validator(val ,[emailValiditor]);
       setEmail({value:val,isValid:result,error:message});
    }
    const handlePhoneInput = (val)=>{
      const {message,result} = validator(val ,[phoneValidator]);
      setPhone({value:val,isValid:result,error:message});
   }
    const handlePasswordInput = (val)=>{
      const {message,result} = validator(val,[passwordValidator])
      setPassword({value:val,isValid:result,error:message});
    }
    const handleConfirmPasswordInput = (val)=>{
        if(val!==password.value)
        setConfirmPassword({value:val,isValid:false,error:"Password does't match"});
        else setConfirmPassword({value:val,isValid:true,error:""});
      }
      const handleOtpInput = (val)=>{
        const {message,result} = validator(val ,[]);
        setOtp({value:val,isValid:result,error:message});
     }
     const handelOtpSubmit = (e)=>{
       e.preventDefault();
        setOtpLoading(true);
        window.confirmationResult.confirm(otp.value).then(async (result) => {
          try{
            await finishSignup(email.value,password.value,name.value);
            window.location.href = "/";
          }catch(e){
            console.log(e);
          
          }
        }).catch((e) => {
           setOtpLoading(false);
           setOtp({...otp,error:e.toString().split(":")[2]});
        });
     }
return (
 <StyledSignUP> 
     <img src="/assets/account.png" alt="" />
      <h1>Sign Up</h1>
    <span>{subError}</span>
    {!verifyPhone ? 
    (<form onSubmit={handleSignup}>
            <AuthInput type="text" required={true} placeholder="Full Name" disabled={loading} error={name.error} handleInput={handleNameInput } />
            <AuthInput type="email" required={true} placeholder="Email" disabled={loading} error={email.error} handleInput={handleEmailInput } />
            <AuthInput type="text" required={true} placeholder="Mobile Phone" disabled={loading} error={phone.error} handleInput={handlePhoneInput} />
            <AuthInput type="password" required={true} placeholder="Password" disabled={loading} error={password.error} handleInput={handlePasswordInput} />
            <AuthInput type="password" required={true} placeholder="Confirm Password" disabled={loading} error={confirmPassword.error} handleInput={handleConfirmPasswordInput} />
            <span>Already have an account? <b onClick={()=>dispatch(setCurrentAuthPage(1))}>Login</b></span>
            <div id='recaptcha-container'></div>
            <AuthButton disabled={loading} label="Register" />
    </form>):
    (<form onSubmit={handelOtpSubmit}>
            <AuthInput type="number" required={true} placeholder="Enter Verification Code" disabled={otpLoading} error={otp.error} handleInput={handleOtpInput} />
            <AuthButton disabled={otpLoading} label="Verify" />
    </form>)}
 </StyledSignUP> 
)
}
