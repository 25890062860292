import React from 'react'
import { StyledAboutUs } from './AboutUs.styled'
export default function AboutUs() {
return (
 <StyledAboutUs> 
     <h1>About Emilka</h1>
     <p>
     Emilka traditional shop, situated in Addis Abeba, Ethiopia, was founded in August 2016 and is a trustworthy community marketplace where individuals can acquire  high-quality traditional clothing they deserve.

Emilka plans to make Ethiopian traditional cloth universal and gorgeous traditional cloth a cultural phenomenon. Emilka was founded by a courageous Ethiopian young female entrepreneur.
    </p>
<div>
    {/* <img src="/assets/bg.jpg" />
    <img src="/assets/bg.jpg" />
    <img src="/assets/bg.jpg" />
    <img src="/assets/bg.jpg" /> */}
</div>
 </StyledAboutUs> 
)
}
