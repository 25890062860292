import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  updateEmail,
  updatePassword,
  signOut,
  updateProfile,
  sendEmailVerification,
  getAuth,
  EmailAuthProvider,
  reauthenticateWithCredential,
  signInWithPhoneNumber,
  RecaptchaVerifier,
} from "firebase/auth";
import {getStorage} from "firebase/storage";
import app from "./config";

export const storage = getStorage(app);
export const auth = getAuth(app); 

export const configRecaptcha = ()=> new RecaptchaVerifier('recaptcha-container', {
     'size': 'invisible',
    'callback': (response) => {
      console.log.apply("recaptcha done");
    },
    'expired-callback': () => {
      console.log("recaptcha failed");
    }
  }, auth);
export const finishSignup = async (email,password,name = "")=>{
  await updateEmail(auth.currentUser,email);
  await updatePassword(auth.currentUser, password);
  await updateProfile(auth.currentUser,{displayName: name,});
}
  export const verifyEmail = async ()=>{
    await sendEmailVerification(auth.currentUser);
  }
  export const login = async (email, password) => {
    await signInWithEmailAndPassword(auth, email, password);
  }
  export const signup = async (phone,appVerifier)=>{
   return signInWithPhoneNumber(auth, phone, appVerifier);
  }
  
  export const resetPassword = async (email)=>{
    await sendPasswordResetEmail(auth, email);
  }

  export const updateUser = async(name,oldEmail,oldPassword,email,password,imgUrl)=>{
    await reauthenticateWithCredential(auth.currentUser,  EmailAuthProvider.credential(oldEmail,oldPassword));
    await updateEmail(auth.currentUser, email);
    await updatePassword(auth.currentUser, password);
    await updateProfile(auth.currentUser, {displayName:name,photoURL: imgUrl,})
  }
  export function logout() {
    return signOut(auth);
  }

