import { createGlobalStyle } from "styled-components";
import Themes from "./theme";
const Global = createGlobalStyle`
@font-face {
    font-family: "input";
    font-weight: 100;
    src: local("Segoe UI Light");
}
 *{
     margin:0;
     padding:0;
     box-sizing:border-box !important;
 }
 body,html,#root{
     width:100%;
     min-height:100vh !important;
     background-color:${Themes.white};
     font-family:Segoe UI;
     margin: auto;
     display: flex;
     flex-direction: column;
     scroll-behavior: smooth;
     background-image: url('/assets/pattern.png');
     background-size: auto auto;
     background-attachment: fixed;
 }
 #location{
     margin-top: auto;
 }
 #inner-root{
     width:100%;
     position: relative;
     z-index:1;
     flex:1;
     display:flex;
     flex-direction:column;
 }
 object{
     width:50px;
     height:50px;
 }
`;
export default Global;   
