import styled from "styled-components";
import Themes from "../../style/theme";
export const StyledNavBar = styled.div`
@keyframes fadeIn{
    from{
        background-color: rgb(68 68 68 / 0);
    }
    to{
       background-color: rgb(68 68 68 / 0.5);
    }
}
 width:100%;
 background-color: ${Themes.primary};
 display: flex;
 justify-content: space-between;
 align-items: center;
 color:${Themes.white};
 box-sizing: border-box;
 position: sticky;
 top:0;
 background-image: url('/assets/pattern.png');
 background-size: auto auto;
 background-attachment: fixed;
 background-blend-mode: multiply;

 z-index: 90;
 .hidden-bg{
     position: fixed;
     top:0;
     bottom:0;
     left:0;
     right:0;
     z-index:1;
     display: none;
     &.hidden-bg--active{
        display: block;
     }
 }
 .auth-page{
   position: fixed;
   z-index: 20;
   top:0;
   bottom:0;
   left:0;
   right:0;
   background-color: rgb(0 0 0 / 0.6);
   display: flex;
   justify-content: center;
   align-items: center;
   overflow:auto;
   transition:all 5s;
   .auth-container{
    transition:all 0.5s;
    animation:fadeIn 0.3s;
    animation-fill-mode: forwards;  
       width: 100%;
       zoom:0.9;
       max-width: 400px;
       margin:5px;
       overflow:hidden;
       position: relative;
       padding:20px;
       border-radius: 10px;
       background-color: rgb(68 68 68 / 0.5);
       display:flex ;
       flex-direction:column;
       & > * {
           width:80%;
           margin:auto;
       }
       & > svg{
           margin:0;
           margin-left:auto !important;
           width:50px;
           cursor: pointer;
       }
       .blur-effect{
            position: absolute;
            width:100vw;
            transform:scale(1.1);
            aspect-ratio:16/9;
            background-image: url("/assets/bg.jpg");
            background-repeat: no-repeat;
            background-size: 100% auto;
            background-position: top;
            filter: blur(5px);
            z-index:-1;
            
        }
    }
 }
 .logo-container{
     margin:5px;
     margin-right:25px;
     font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
     img{
         width:100px;
     }
     a{
         text-decoration:none;
         color:${Themes.white};
     }
}
 & > .nav-links > ul{
     list-style-type: none;
     display: flex;
     justify-content:center;
     flex-wrap: wrap;
     & > svg{
         display: none;
     }
     & > li{
         margin:10px;
        transition: opacity 0.5s;
        a{
            text-decoration: none;
            color:${Themes.white}
        }
         &:hover{
            cursor: pointer;
            opacity: 0.7;
         }
     }

 }
 & > .nav-links{
     display: flex;
     margin-right:auto;
 }
 .menu-svg{
     display: none;
 }
 & > .nav-tools{
     flex:1;
     max-width: 300px;
     display: flex;
     justify-content: flex-end;
     align-items: center;
     padding:5px;
     margin-right:35px;
     box-sizing: border-box !important;
     .cart-cont  svg{
         color:${Themes.white} !important;
         &:active{
            color:${Themes.white} !important;
         }
     }
     .cart-cont > span{
         position: absolute;
         margin-left:15px;
         margin-top:-5px;
         background-color: ${Themes.bage};
         padding: 5px;
         font-size: 0.7em;
         border-radius: 15px;
         min-width:15px;
         height:15px;
         display: block;
         display: flex;
         align-items: center;
         justify-content: center;
     }
     .avator-container{
         width:40px;
         aspect-ratio:1/1;
         background-color: ${Themes.white};
         border-radius:50%;
         overflow: hidden;
         margin-right:10px;
         img{
           width:40px;
           height:40px;
           object-fit: cover;
         }
         ul{
             height:0;
             overflow: hidden;
             position: absolute;
             margin-left:-40px;
             padding:0px;
             border-radius:5px;
             box-shadow:1px 1px 5px rgba(0,0,0,0.3);
             list-style-type: none;
             background-color: ${Themes.white};
             color: ${Themes.darkBack};
             transition: all 0.3s;
             opacity: 0;
             display: flex;
             flex-direction: column;
             justify-content: center;
             z-index:2;
             &.profiledropdown-active{
                height:150px;
                padding:5px;
                opacity: 1;
             }
             & > span{
                     padding:10px;
                     padding-bottom:5px;
                     border-bottom:1px solid ${Themes.lightGrey};
                 }
             li{
                 padding:10px;
                 transition: opacity 0.5s;
                 &:hover{
                     opacity: 0.5;
                     cursor: pointer;
                 }
                 & > a,& > a:active{
                     text-decoration:none ;
                     color:${Themes.darkBack}
                 }
             }
         }
     }
     & > h5{
        box-sizing: border-box;
         margin:10px;
         background-color:${Themes.white};
         padding: 5px 25px;
         font-weight:100;
         border-radius:50px;
         color:${Themes.primary};
         cursor:pointer;
         word-wrap: break-word;
         white-space: nowrap;
     }
 }
 @media only screen and (max-width: 600px) {
        h1{
            font-size:1.3em;
        }
        .logo-container{
            margin-right: 5px;
            img{
               width:80px;
            }
        }
        .menu-svg{
            display: block;
        }
        & > .nav-tools{
           flex-wrap: wrap;
            & > h5{
                font-size:0.5em;
                padding:5px 15px;
                margin:5px ;
            }
            .avator-container{
                width:30px;
            }
        }
        & > .nav-links > ul{
            height:0;
            overflow: hidden;
            position: fixed;
            top:0;
            left:0;
            width: 100vw;
            background-color: ${Themes.primary};
            background-image: url('/assets/pattern.png');
            background-size: auto auto;
            background-attachment: fixed;
            flex-direction: column;
            align-items: center;
            z-index: 9;
            transition: height 0.3s;
            &.active-nav{
                height:100vh;
            }
            & > li{
                min-width:100px;
                &:last-child{
                    margin-bottom: auto;
                }
            }
            & > svg{
              display: block;
              margin-right:auto;
              margin-left:15px;
              margin-top:15px;
              margin-bottom: auto;
              transform: scale(1.5);
           }
        }
      
    }
`
